import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useNavigate } from "react-router-dom";

const MembershipCard = () => {
  const [selectedPlan, setSelectedPlan] = React.useState("1luna");
  const navigate = useNavigate();
  const handlePlanChange = (event, newPlan) => {
    if (newPlan !== null) {
      setSelectedPlan(newPlan);
    }
  };

  // Define the membership plans
  const plans = {
    "1luna": {
      price: "49,99 RON / lună",
      originalPrice: "89,99 RON/lună",
      benefits: [
        "50 + rețete noi în fiecare lună",
        "Plan alimentar personalizat",
        "Listă de cumpărături",
        "Opțiune de livrare a ingredientelor"
      ],
    },
    "3luni": {
      price: "69,99 RON/lună",
      originalPrice: "119,97 RON/3 luni",
      benefits: [
        "50 + rețete noi în fiecare lună",
        "Plan alimentar personalizat",
        "Listă de cumpărături",
        "Opțiune de livrare a ingredientelor"
      ],
    },
    "12luni": {
      price: "19,99 RON/lună",
      originalPrice: "239,88 RON/an",
      benefits: [
        "50 + rețete noi în fiecare lună",
        "Plan alimentar personalizat",
        "Listă de cumpărături",
        "Opțiune de livrare a ingredientelor"
      ],
    },
  };

  const { price, originalPrice, benefits } = plans[selectedPlan];

  return (
    <Container className="my-4" style={{ fontFamily: "Poppins" }}>
      <Card
        className="p-4"
        style={{ borderRadius: "10px", backgroundColor: "#FFFDF2" }}
      >
        <Row className="justify-content-center mb-4">
          <ToggleButtonGroup
            value={selectedPlan}
            exclusive
            onChange={handlePlanChange}
            aria-label="Plan selection"
            sx={{
              width: "100%", // Span the entire column,
            }}
          >
            <ToggleButton
              value="1luna"
              aria-label="1 luna"
              sx={{
                fontWeight: "bold",
                fontFamily: "Poppins",
                flex: 1, // Make buttons equally take up space
                textTransform: "none",
                backgroundColor: "#F3A7BB",
                color: "#fff",
                "&.Mui-selected": {
                  backgroundColor: "#F8568D",
                  color: "#fff",
                  // borderRadius: '20px',
                },
                "&:hover": {
                  backgroundColor: "#F3A7BB",
                },
              }}
            >
              1 lună
            </ToggleButton>
            <ToggleButton
              value="3luni"
              aria-label="3 luni"
              sx={{
                fontWeight: "bold",
                fontFamily: "Poppins",
                flex: 1, // Make buttons equally take up space
                textTransform: "none",
                backgroundColor: "#F3A7BB",
                color: "#fff",
                "&.Mui-selected": {
                  backgroundColor: "#F8568D",
                  color: "#fff",
                  // borderRadius: '20px',
                },
                "&:hover": {
                  backgroundColor: "#F3A7BB",
                },
              }}
            >
              3 luni
            </ToggleButton>
            <ToggleButton
              value="12luni"
              aria-label="12 luni"
              sx={{
                fontWeight: "bold",
                fontFamily: "Poppins",
                flex: 1, // Make buttons equally take up space
                textTransform: "none",
                backgroundColor: "#F3A7BB",
                color: "#fff",
                "&.Mui-selected": {
                  backgroundColor: "#F8568D",
                  color: "#fff",
                  // borderRadius: '20px',
                },
                "&:hover": {
                  backgroundColor: "#F3A7BB",
                },
              }}
            >
              12 luni
            </ToggleButton>
          </ToggleButtonGroup>
        </Row>
        <Row>
          <Col>
            <Typography
              variant="h5"
              component="div"
              sx={{
                fontWeight: "bold",
                marginBottom: "0.5rem",
                fontFamily: "Poppins",
              }}
            >
              Bloomplate Membership <span style={{ color: "red" }}>*</span>
            </Typography>
            <Typography
              variant="h6"
              component="div"
              style={{
                textDecoration: originalPrice === "89,99 RON/lună" ?  "line-through" : "",
                color: "#999",
                fontFamily: "Poppins",
                fontWeight: "bolder"
              }}
            >
              {originalPrice}
            </Typography>
            <Typography
              variant="h3"
              component="div"
              style={{
                fontWeight: "bold",
                color: "#FF2C64",
                fontFamily: "Poppins",
              }}
            >
              {price}
            </Typography>
            <Typography
              variant="body2"
              component="div"
              sx={{
                fontFamily: "Poppins",
                fontWeight: "bold",
                marginTop: 2,
                marginBottom: 2,
              }}
            >
              Include:
            </Typography>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {benefits.map((text, index) => (
                <li
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0.5rem",
                  }}
                >
                  <CheckIcon
                    style={{ color: "#FF2C64", marginRight: "0.5rem" }}
                  />
                  <Typography variant="body2" sx={{ fontFamily: "Poppins" }}>
                    {text}
                  </Typography>
                </li>
              ))}
            </ul>
            <div>
              <Button
                onClick={() => navigate("/questionaire/age")}
                className="m-2"
                style={{
                  backgroundColor: "#FF2C64",
                  border: "none",
                  borderRadius: "8px",
                  padding: "10px 20px",
                  fontWeight: 400,
                }}
              >
                Cumpără acces la platformă
              </Button>
              <Button
                onClick={() => navigate("/questionaire/age")}
                className="m-2"
                style={{
                  backgroundColor: "#DDF652",
                  border: "none",
                  borderRadius: "8px",
                  padding: "10px 20px",
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                Încearcă pe gratis
              </Button>
            </div>
            <Typography
              variant="body2"
              component="div"
              style={{
                fontSize: "12px",
                marginTop: "1rem",
                textAlign: "center",
                color: "#999",
                fontFamily: "Poppins",
              }}
            >
              *100% gratuit
            </Typography>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default MembershipCard;
