import { Typography } from "@mui/material";
import React, { useState } from "react";
import { Button, Card, Col, Form, Row, Stack, Modal } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import ButtonPrimary from "../buttons/ButtonPrimary";
import ButtonSecondary from "../buttons/ButtonSecondary";

const ShoppingListCard = ({ ingredients }) => {
  const ingredientNames = ingredients.map((item) => item.ingredient.name);
  const amountUnits = ingredients.map(
    (item) =>
      `${parseFloat(item.amount).toFixed(2).replace(/\.00$/, "")} ${item.unit}`
  );

  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Card
      style={{
        fontFamily: "Poppins",
        maxHeight: "400px",
        display: "flex", // Make the card a flex container
        flexDirection: "column", // Stack children vertically
        backgroundColor: "#FFFEF7",
      }}
      className="shadow-sm mt-4"
    >
      <Card.Body style={{ flex: "1 1 auto", overflowY: "auto" }}>
        <Stack gap={2} direction="horizontal">
          <Typography sx={{ fontFamily: "Poppins", fontWeight: "600" }}>
            Listă de cumpărături
          </Typography>
          <Button
            onClick={() => navigate("/dashboard/shopping-list")}
            className="ms-auto"
            style={{
              background: "none",
              border: "none",
              color: "#FF2C64",
              textDecoration: "underline",
            }}
          >
            Vezi tot
          </Button>
        </Stack>
        <hr style={{ border: "1px solid #CFCFCF" }} />
        {ingredientNames &&
          ingredientNames.map((name, index) => (
            <React.Fragment key={index}>
              <Row className="align-items-center mb-3 mt-4">
                <Col xs="auto">
                  <Form.Check type="checkbox" />
                </Col>
                <Col>
                  <span>{name}</span>
                </Col>
                <Col className="text-right">
                  <span>{amountUnits[index]}</span>
                </Col>
              </Row>
              <hr style={{ border: "1px solid #CFCFCF" }} />
            </React.Fragment>
          ))}
        <Typography
          className="text-center"
          style={{
            background: "none",
            border: "none",
            color: "#FF2C64",
            textDecoration: "underline",
            fontFamily: "Poppins",
            cursor: "pointer",
          }}
          onClick={() => navigate("/dashboard/shopping-list")}
        >
          Vezi tot
        </Typography>
      </Card.Body>
      <Card.Footer className="text-center">
        <Button
          style={{
            border: "none",
            backgroundColor: "#FF2C64",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
          className="w-100"
          onClick={() => handleShow()}
        >
          Comandă acum
        </Button>
      </Card.Footer>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontFamily: "Poppins, Arial, sans-serif" }}>
            Livrare ingrediente
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontFamily: "Poppins, Arial, sans-serif" }}>
          Mulțumim pentru interesul acordat funcției de livrare! Lucrăm intens
          la dezvoltarea ei și va fi disponibilă în curând. Dacă ești interesat
          de mai multe detalii, ne poți scrie pe Instagram la @bloomplate.
        </Modal.Body>
        <Modal.Footer>
          <ButtonSecondary text="Inchide" onClick={handleClose} />
          <ButtonPrimary text="Am inteles" onClick={handleClose} />
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default ShoppingListCard;
