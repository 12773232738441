import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Stack,
  Button,
  Form,
  InputGroup,
  Card,
  Modal,
  Spinner,
} from "react-bootstrap";
import CheckIcon from "@mui/icons-material/Check";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardSidebar from "../../components/navbar/DashboardSidebar";
import { Avatar, Typography } from "@mui/material";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import FAQCard from "../../components/cards/FAQCard";
import SearchCard from "../../components/cards/SearchCard";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Box, useMediaQuery } from "@mui/system";
import { AuthContext } from "../../context/AuthContext";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ButtonSecondary from "../../components/buttons/ButtonSecondary";
import BlackButton from "../../components/buttons/BlackButton";
import AssignmentIcon from "@mui/icons-material/Assignment";

const ProfileSettings = () => {
  const [showModal, setShowModal] = useState(false);
  const [activeButton, setActiveButton] = useState("Setări");
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const [isDashboardClicked, setIsDashboardClicked] = useState(false);
  const [isCumpClicked, setIsCumpClicked] = useState(false);
  const [isProfilClicked, setIsProfilClicked] = useState(false);
  const [isLogOutClicked, setIsLogOutClicked] = useState(false);
  const token = Cookies.get("authToken");
  const [isRecommendationsClicked, setIsRecommendationsClicked] =
    useState(false);
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [plan, setPlan] = useState(0);
  const [alimentationType, setAlimentationType] = useState("");
  const [mainObjective, setMainObjective] = useState("");
  const [foodsToBeExcluded, setFoodsToBeExcluded] = useState([]);
  const [time, setTime] = useState("");
  const [kcal, setKcal] = useState(0);
  const [last4, setLast4] = useState("0000");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [status, setStatus] = useState("");
  const [duePayment, setDuePayment] = useState(null);
  const [cancelledDueDate, setCancelledDueDate] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [wantsNewsletter, setWantsNewsletter] = useState(false);

  const handleClose = () => setShow(false);
  const monthsRo = [
    "Ianuarie",
    "Februarie",
    "Martie",
    "Aprilie",
    "Mai",
    "Iunie",
    "Iulie",
    "August",
    "Septembrie",
    "Octombrie",
    "Noiembrie",
    "Decembrie",
  ];

  const [expandedPanel, setExpandedPanel] = useState(null);

  // Handle the accordion toggle
  const handleAccordionChange = (panel) => (_, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null); // Set to null when collapsed
  };

  const [mealPreference, setMealPreference] = useState("");
  const navigate = useNavigate();

  const GET_CURRENT_USER_AND_SUBSCRIPTION = `
    query RetrieveSubscription {
      currentSubscription {
        subscriptionId
        status
        paymentMethod {
          last4
        }
        nextPaymentDue
        username
        email
        phoneNumber
        plan
        alimentationType
        time
        kcal
        mealPreference
        foodsToBeExcluded
        mainObjective
        currentPeriodEnd
        wantsNewsletter
      }
    }
  `;

  useEffect(() => {
    // Check if the user is authenticated
    if (isAuthenticated && token) {
      axios
        .post(
          "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
          {
            query: GET_CURRENT_USER_AND_SUBSCRIPTION,
          },
          {
            headers: {
              Authorization: `JWT ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const data = response.data.data.currentSubscription;

          // Set state with user and subscription data
          setUsername(data.username);
          setEmail(data.email);
          setPhoneNumber(data.phoneNumber);
          setPlan(data.plan);
          setAlimentationType(data.alimentationType);
          setTime(data.time);
          setKcal(data.kcal);
          setMealPreference(data.mealPreference);

          // Split and trim foods to be excluded
          setFoodsToBeExcluded(
            data.foodsToBeExcluded.split(", ").map((food) => food.trim())
          );

          setMainObjective(data.mainObjective);
          setWantsNewsletter(data.wantsNewsletter);

          // Subscription details
          if (data.paymentMethod) {
            setLast4(data.paymentMethod.last4);
          } else {
            setLast4("0000");
          }

          if (data.status === "canceled") {
            setStatus("Anulat");
          } else {
            setStatus("Activ");
          }
          setDuePayment(data.nextPaymentDue);
          setCancelledDueDate(data.currentPeriodEnd);
          setSubscriptionId(data.subscriptionId);
        })
        .catch((error) => {
          console.error("There was an error fetching the profile data!", error);
        });
    }
  }, [isAuthenticated, token, logout]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

    return `${day}/${month}/${year}`;
  };

  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const sendEmail = () => {
    console.log(message);
    console.log(email);
    let query = `
    mutation SendFeedbackMutation($feedback: String!, $email: String!) { sendFeedback(feedback: $feedback, email: $email) { success error } }`;
    axios
      .post(
        "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
        { query: query, variables: { feedback: message, email: email } },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("a mers");
      })
      .catch((error) => {
        console.log("nu a mers");
      });
  };

  const getDate = () => {
    if (duePayment) {
      const date = new Date(duePayment * 1000); // If your timestamp is in seconds

      // Extract year, month, and day
      const year = date.getFullYear();
      const month = monthsRo[date.getMonth()]; // getMonth() returns 0-11
      const day = date.getDate();

      // Create the formatted date string in Romanian
      const formattedDate = `${day} ${month} ${year}`;
      return formattedDate;
    } else if (cancelledDueDate) {
      const date = new Date(cancelledDueDate * 1000); // If your timestamp is in seconds

      // Extract year, month, and day
      const year = date.getFullYear();
      const month = monthsRo[date.getMonth()]; // getMonth() returns 0-11
      const day = date.getDate();

      // Create the formatted date string in Romanian
      const formattedDate = `${day} ${month} ${year}`;
      return formattedDate;
    } else {
      return null;
    }
  };

  const payBill = async (paymentId) => {
    try {
      const response = await axios({
        method: "POST",
        url: "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`, // If you're using a token-based auth system
        },
        data: {
          query: `
            mutation PayPayment($paymentId: String!) {
              payPayment(paymentId: $paymentId) {
                success
                message
              }
            }
          `,
          variables: {
            paymentId,
          },
        },
      });

      const { data } = response;
      if (data.errors) {
        console.error("Error:", data.errors);
      } else {
        const { success, message } = data.data.payPayment;
        if (success) {
          console.log("Payment successful:", message);
        } else {
          console.error("Payment failed:", message);
        }
      }
    } catch (error) {
      console.error("Error during payment:", error);
    }
  };

  const cancelSubscription = () => {
    const query = `
        mutation CancelSubscription {
            cancelSubscription {
                subscriptionId
                status
                error
            }
        }
    `;

    setLoading(true);

    axios
      .post(
        "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
        { query: query },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((response) => {
        const { data } = response;
        setStatus("Anulat");

        // Check the result
        if (data.errors) {
          console.error("GraphQL errors:", data.errors);
          return;
        }

        const { subscriptionId, status, error } = data.data.cancelSubscription;

        if (error) {
          console.error("Error canceling subscription:", error);
        } else {
          console.log("Subscription canceled:", subscriptionId, status);
          setShow(false);
          setLoading(false);
          // navigate(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeUser = (newWantsNewsletterValue) => {
    let data = {
      username: username,
      phoneNumber: phoneNumber,
      email: email,
      wantsNewsletter: newWantsNewsletterValue,
      alimentationType: alimentationType,
    };
    const query = `
    mutation UserUpdate($userData: UserUpdateInput!) {
      userUpdate(userData: $userData) {
        success
        token
      }
    }
  `;
    axios
      .post(
        "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
        {
          query: query,
          variables: {
            userData: data,
          },
        },
        {
          headers: {
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response.data;
        const token = data.data.userUpdate.token;
        Cookies.set("authToken", token);
      })
      .catch((error) => {
        console.error("Error updating user data:", error);
      });
  };

  const fetchPayments = async () => {
    try {
      const response = await axios.post(
        "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
        {
          query: `
            query RetrievePayments {
              payments {
                id
                amount
                currency
                status
                created
                dueDate
                subscriptionId
                invoiceUrl
              }
            }
          `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      );

      if (response.data.errors) {
        console.error("GraphQL Errors:", response.data.errors);
        return [];
      }

      return response.data.data.payments; // Returns the payments array
    } catch (error) {
      console.error("Error fetching payments:", error);
      return [];
    }
  };

  const handleCheckboxChange = () => {
    const newValue = !wantsNewsletter; // Toggle the current state
    setWantsNewsletter(newValue); // Update the state

    // Call changeUser with the updated state value
    changeUser(newValue);
  };

  const handleClick = async (value) => {
    setActiveButton(value);

    if (value === "istoric") {
      try {
        const payments = await fetchPayments();
        if (payments) {
          setPayments(payments); // Assume you have a `setPayments` state for storing payments
        } else {
          console.error("No payments found or an error occurred.");
        }
      } catch (error) {
        console.error("Error fetching payments:", error);
      }
    }
  };

  // Function to handle button click
  const renderText = () => {
    // Set different content based on button clicked
    switch (activeButton) {
      case "Setări":
        return (
          <>
            <Row className="mt-4">
              <Col lg={5}>
                <Form.Label
                  for="name"
                  style={{
                    color: "#D3D3D3",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                  }}
                >
                  User Name
                </Form.Label>
                <InputGroup className="mt-2 mb-3">
                  <Form.Control
                    id="name"
                    style={{
                      backgroundColor: "transparent",
                      fontFamily: "Poppins",
                    }}
                    type="text"
                    aria-label="Amount (to the nearest dollar)"
                    placeholder="Numele tău"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </InputGroup>

                <Form.Label
                  for="email"
                  style={{
                    color: "#D3D3D3",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                  }}
                >
                  Email
                </Form.Label>
                <InputGroup className="mt-2 mb-3">
                  <Form.Control
                    id="email"
                    style={{
                      backgroundColor: "transparent",
                      fontFamily: "Poppins",
                    }}
                    type="email"
                    aria-label="Amount (to the nearest dollar)"
                    placeholder="nume@email.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
                <Form.Label
                  for="phone"
                  style={{
                    color: "#D3D3D3",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                  }}
                >
                  Telefon
                </Form.Label>
                <InputGroup className="mt-2 mb-3">
                  <Form.Control
                    id="phone"
                    style={{
                      backgroundColor: "transparent",
                      fontFamily: "Poppins",
                    }}
                    type="text"
                    aria-label="Amount (to the nearest dollar)"
                    placeholder="+40729565538"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </InputGroup>
              </Col>
              {/* <Row> */}
              {/* <Col lg={6}> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: isMobile ? "center" : "flex-start", // Center if mobile, otherwise left-align,
                  width: "10",
                }}
              >
                <Button
                  className="mt-2"
                  style={{
                    backgroundColor: "#FF2C64",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    fontWeight: 400,
                    borderRadius: 10,
                    textTransform: "none",
                    padding: "8px 16px",
                    width: isMobile ? "100%" : 200, // Ensure the div takes the full width of the container

                    border: "none",
                    whiteSpace: "nowrap",
                    "&:hover": {
                      backgroundColor: "#FF2C64",
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => changeUser()}
                >
                  Schimbă datele
                </Button>
              </div>

              <Typography
                className="mt-5"
                style={{ fontWeight: "bold", fontFamily: "Poppins" }}
              >
                Preferințe mail
              </Typography>
              <Stack className="mt-2 mb-5" direction="horizontal" gap={3}>
                <Form.Check
                  onClick={() => {
                    handleCheckboxChange();
                  }}
                  checked={wantsNewsletter}
                  type="checkbox"
                />
                <span className="ms-2" style={{ fontFamily: "Poppins" }}>
                  Da, vreau să primesc mai multe oferte.
                </span>
              </Stack>
              <Typography
                className="mt-2"
                style={{ fontWeight: "bold", fontFamily: "Poppins" }}
              >
                Ieșire din cont
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: isMobile ? "center" : "flex-start", // Center if mobile, otherwise left-align
                }}
              >
                <Button
                  className="mt-4"
                  style={{
                    backgroundColor: "#FF2C64",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    fontWeight: 400,
                    borderRadius: 10,
                    textTransform: "none",
                    padding: "8px 16px",
                    width: isMobile ? "100%" : 200, // Ensure the div takes the full width of the container
                    border: "none",
                    whiteSpace: "nowrap",
                    "&:hover": {
                      backgroundColor: "#FF2C64",
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => logout()}
                >
                  Log Out
                </Button>
              </div>
              {/* </Col> */}
              {/* </Row> */}
            </Row>
          </>
        );
      case "Abonament":
        return (
          <>
            <Row className="mt-5">
              <Container className="my-4" style={{ fontFamily: "Poppins" }}>
                <Card
                  className="p-4"
                  style={{ borderRadius: "10px", backgroundColor: "#FFFEF7" }}
                >
                  <Row>
                    <Col>
                      <Button
                        size="sm"
                        className="text-center"
                        variant="outline-dark"
                        style={{
                          borderRadius: "14px",
                          border: "1px solid black",
                          padding: "12px",
                          paddingLeft: "20px",
                          paddingRight: "20px",
                          fontSize: "16px",
                          display: "flex",
                          alignItems: "center",
                          fontFamily: "Poppins",
                          backgroundColor: "transparent",
                          color: "black",
                        }}
                      >
                        {status}
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        fontWeight: "bold",

                        fontFamily: "Poppins",
                      }}
                    >
                      Bloomplate Membership
                    </Typography>
                    {/* <Typography
                      className="mt-2"
                      variant="h6"
                      component="div"
                      sx={{
                        fontWeight: "normal",

                        fontFamily: "Poppins",
                        textDecoration: "underline",
                      }}
                    >
                      Modifică abonamentul
                    </Typography> */}
                    <Typography
                      className="mt-4"
                      variant="h4"
                      component="div"
                      style={{
                        fontWeight: "bold",
                        color: "#FF2C64",
                        fontFamily: "Poppins",
                      }}
                    >
                      {plan === 3 && "19,99 RON/lună"}
                      {plan === 2 && "39,99 RON/lună"}
                      {plan === 1 && "49,99 RON/lună"}
                    </Typography>
                    <Typography
                      className="mt-2"
                      variant="h6"
                      component="div"
                      style={{
                        fontWeight: "bold",
                        color: "gray",
                        fontFamily: "Poppins",
                      }}
                    >
                      {plan === 3 && "239,88 RON/an"}
                      {plan === 2 && "119,97 RON/3 luni"}
                      {plan === 1 && "49,99 RON/lună"}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        marginTop: 2,
                        marginBottom: 2,
                      }}
                    >
                      Include:
                    </Typography>
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      {[
                        "50 + rețete noi în fiecare lună",
                        "Plan alimentar personalizat",
                        "Listă de cumpărături",
                        "Opțiune de livrare a ingredientelor",
                      ].map((text, index) => (
                        <li
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <CheckIcon
                            style={{ color: "#FF2C64", marginRight: "0.5rem" }}
                          />
                          <Typography
                            variant="body2"
                            sx={{ fontFamily: "Poppins" }}
                          >
                            {text}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </Row>
                </Card>
                <Row className="mt-4">
                  <Typography
                    className="mb-2 mt-4"
                    variant="h6"
                    component="div"
                    sx={{
                      fontWeight: "bold",

                      fontFamily: "Poppins",
                    }}
                  >
                    Facturare și plată
                  </Typography>
                  {status === "Activ" && (
                    <>
                      <Stack className="mt-2" direction="horizontal">
                        <Typography
                          variant="body2"
                          component="div"
                          sx={{
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                          }}
                        >
                          Metoda de plată
                        </Typography>

                        <Typography
                          className="ms-auto"
                          variant="body2"
                          component="div"
                          sx={{
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                          }}
                        >
                          ending in **{last4}
                        </Typography>
                      </Stack>
                      <hr
                        className="mt-2 px-4"
                        style={{ border: "1px solid #CFCFCF" }}
                      />
                    </>
                  )}

                  <Stack direction="horizontal">
                    <Typography
                      variant="body2"
                      component="div"
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                      }}
                    >
                      {status === "Activ"
                        ? "Următoarea plată"
                        : "Cont valabil până la"}
                      {/* Următoarea plată */}
                    </Typography>

                    <Typography
                      className="ms-auto"
                      variant="body2"
                      component="div"
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                      }}
                    >
                      {getDate()}
                    </Typography>
                  </Stack>
                  <Row className="mt-4">
                    {/* <Typography
                      className="mt-2"
                      variant="body2"
                      component="div"
                      sx={{
                        fontWeight: "normal",

                        fontFamily: "Poppins",
                        textDecoration: "underline",
                      }}
                    >
                      Modifică metoda de plată
                    </Typography>
                    <Typography
                      className="mt-2"
                      variant="body2"
                      component="div"
                      sx={{
                        fontWeight: "normal",

                        fontFamily: "Poppins",
                        textDecoration: "underline",
                      }}
                    >
                      Vezi istoricul plăților
                    </Typography> */}
                    {status === "Activ" && (
                      <>
                        <Typography
                          className="mt-2"
                          variant="body2"
                          component="div"
                          sx={{
                            fontWeight: "normal",
                            fontFamily: "Poppins",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => setShow(true)}
                        >
                          Anulează subscriptia
                        </Typography>
                        {/* <Typography
                          className="mt-2"
                          variant="body2"
                          component="div"
                          sx={{
                            fontWeight: "normal",
                            fontFamily: "Poppins",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          // onClick={() => setShow(true)}
                        >
                          Schimba metoda de plata
                        </Typography> */}
                        <Typography
                          className="mt-2"
                          variant="body2"
                          component="div"
                          sx={{
                            fontWeight: "normal",
                            fontFamily: "Poppins",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => handleClick("istoric")}
                        >
                          Istoricul platilor
                        </Typography>
                      </>
                    )}
                    <Modal show={show} onHide={handleClose} centered>
                      <Modal.Header closeButton>
                        <Modal.Title
                          style={{ fontFamily: "Poppins, Arial, sans-serif" }}
                        >
                          Anuleaza subscriptia
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body
                        style={{ fontFamily: "Poppins, Arial, sans-serif" }}
                      >
                        Ești sigur că vrei să anulezi abonamentul? Platforma
                        Bloomplate se îmbunătățește în fiecare săptămână și te
                        va surprinde mereu cu rețete noi și delicioase! În plus,
                        schimbările necesită timp pentru a da rezultate… crezi
                        că ți-ai acordat suficient timp?
                      </Modal.Body>
                      <Modal.Footer className="ml-auto">
                        <BlackButton
                          color="#000"
                          text="Inchide"
                          onClick={handleClose}
                        />
                        {/* <ButtonSecondary color="#000" text="Inchide" onClick={handleClose} /> */}
                        <ButtonPrimary
                          marginLeft={10}
                          onClick={() => cancelSubscription()}
                          text={
                            loading ? (
                              <Spinner animation="border" />
                            ) : (
                              "Da, anulez"
                            )
                          }
                        />
                      </Modal.Footer>
                    </Modal>
                  </Row>
                </Row>
              </Container>
            </Row>
          </>
        );
      case "Plan":
        return (
          <>
            <Row className="mt-4">
              <Row
              // className="py-3"
              onClick={() => handleClick("schimba alimente")}
              style={{ cursor: "pointer" }}
              >
                <h6 style={{ fontFamily: "Poppins", fontWeight: "bold" }}>
                  Dietă
                </h6>

                <Col xs={10}>
                  <Stack gap={2}>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "normal",
                        margin: 0,
                      }}
                    >
                      {alimentationType}
                    </p>
                  </Stack>
                </Col>
                <Col
                  xs={2}
                  className="d-flex align-items-center justify-content-end"
                >
                  <ChevronRightIcon />
                </Col>
                <hr
                  className="mt-2 px-4"
                  style={{ border: "1px solid #CFCFCF" }}
                />
              </Row>
              <Row>
                <h6 style={{ fontFamily: "Poppins", fontWeight: "bold" }}>
                  Obiectiv de sănătate
                </h6>
                <Col xs={10}>
                  <Stack gap={2}>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "normal",
                        margin: 0,
                      }}
                    >
                      {mainObjective}
                    </p>
                  </Stack>
                </Col>
                {/* <Col
                  xs={2}
                  className="d-flex align-items-center justify-content-end"
                >
                  <ChevronRightIcon />
                </Col> */}
                <hr
                  className="mt-2 px-4"
                  style={{ border: "1px solid #CFCFCF" }}
                />
              </Row>
              <Row>
                {/* <h6 style={{ fontFamily: "Poppins", fontWeight: "bold" }}>
                  Restricții alimentare
                </h6> */}
                {/* <Stack className="mb-2 mt-2" direction="horizontal" gap={2}>
                  {foodsToBeExcluded.map((food, index) => (
                    <Button
                      key={index} // Use a unique key for each button
                      style={{
                        backgroundColor: "#E7E5DC",
                        border: "none",
                        color: "black",
                        fontFamily: "Poppins",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "20px",
                      }}
                    >
                      {food}
                      <CloseIcon
                        style={{ color: "#999891", marginLeft: "4px" }}
                      />
                    </Button>
                  ))}
                </Stack> */}
                {/* <Stack direction="horizontal" gap={2}>
                  <Box
                    className="p-2"
                    sx={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: "#FF2C64",
                      borderRadius: "20px",
                      border: "1px solid #FF2C64",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <AddIcon
                      fontSize="medium"
                      sx={{ color: "#fff", fontWeight: "bold" }}
                    />
                  </Box>
                </Stack> */}
                {/* <hr
                  className="mt-2 px-4"
                  style={{ border: "1px solid #CFCFCF" }}
                /> */}
              </Row>
              <Row>
                <h6 style={{ fontFamily: "Poppins", fontWeight: "bold" }}>
                  Timp acordat gătitului{" "}
                </h6>
                <Col xs={10}>
                  <Stack gap={2}>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "normal",
                        margin: 0,
                      }}
                    >
                      {time}
                    </p>
                  </Stack>
                </Col>
                <Col
                  xs={2}
                  className="d-flex align-items-center justify-content-end"
                >
                  <ChevronRightIcon />
                </Col>
                <hr
                  className="mt-2 px-4"
                  style={{ border: "1px solid #CFCFCF" }}
                />
              </Row>
              <Row>
                <h6 style={{ fontFamily: "Poppins", fontWeight: "bold" }}>
                  Organizare gătit{" "}
                </h6>
                <Col xs={10}>
                  <Stack gap={2}>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "normal",
                        margin: 0,
                      }}
                    >
                      {mealPreference}
                    </p>
                  </Stack>
                </Col>
                <Col
                  xs={2}
                  className="d-flex align-items-center justify-content-end"
                >
                  <ChevronRightIcon />
                </Col>
                <hr
                  className="mt-2 px-4"
                  style={{ border: "1px solid #CFCFCF" }}
                />
              </Row>
              <Row>
                <h6 style={{ fontFamily: "Poppins", fontWeight: "bold" }}>
                  Aport kcal{" "}
                </h6>
                <Col xs={10}>
                  <Stack gap={2}>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "normal",
                        margin: 0,
                      }}
                    >
                      {kcal == 1800 ? "1500 - 1800 kcal" : "1200 - 1500 kcal"}
                    </p>
                  </Stack>
                </Col>
                <Col
                  xs={2}
                  className="d-flex align-items-center justify-content-end"
                >
                  <ChevronRightIcon />
                </Col>
                <hr
                  className="mt-2 px-4"
                  style={{ border: "1px solid #CFCFCF" }}
                />
              </Row>
            </Row>
          </>
        );
      case "Help Center":
        return (
          <>
            <Row className="mt-4">
              <Col lg={6} className="">
                <Typography
                  style={{ fontWeight: "bold", fontFamily: "Poppins" }}
                >
                  Ai nevoie de ajutor?
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: isMobile ? "center" : "flex-start", // Center if mobile, otherwise left-align
                  }}
                >
                  <Button
                    className="mt-4"
                    style={{
                      backgroundColor: "#FF2C64",
                      fontFamily: "Poppins",
                      fontSize: 16,
                      fontWeight: 400,
                      borderRadius: 10,
                      textTransform: "none",
                      padding: "8px 16px",
                      width: isMobile ? "100%" : 200, // Ensure the div takes the full width of the container

                      border: "none",
                      whiteSpace: "nowrap",
                      "&:hover": {
                        backgroundColor: "#FF2C64",
                        boxShadow: "none",
                      },
                    }}
                    onClick={() =>
                      window.open("mailto:bloomplatesocial@gmail.com", "_blank")
                    }
                  >
                    Chat with us
                  </Button>

                  <Modal show={showModal} onHide={handleCloseModal} centered>
                    <Modal.Header closeButton>
                      <Modal.Title
                        style={{ fontFamily: "Poppins, Arial, sans-serif" }}
                      >
                        Chat with us - feedback
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                      style={{ fontFamily: "Poppins, Arial, sans-serif" }}
                    >
                      Ne dorim să fim mai buni! Ajută-ne să îmbunătățim
                      platforma pentru tine.
                      <Form.Control
                        className="mt-3"
                        as="textarea"
                        rows={3}
                        value={message}
                        onChange={handleChange}
                        placeholder="Scrie-ne sugestiile tale..."
                      />
                    </Modal.Body>

                    <Modal.Footer>
                      <ButtonSecondary
                        text="Inchide"
                        onClick={handleCloseModal}
                      />
                      <ButtonPrimary
                        text="Trimite feedback"
                        onClick={sendEmail}
                      />
                    </Modal.Footer>
                  </Modal>
                </div>

                <Typography
                  className="mt-4"
                  style={{ fontWeight: "bold", fontFamily: "Poppins" }}
                >
                  Feedback
                </Typography>

                <Button
                  className="mt-4"
                  style={{
                    backgroundColor: "#FF2C64",
                    fontFamily: "Poppins",
                    fontSize: 16,
                    fontWeight: 400,
                    borderRadius: 10,
                    textTransform: "none",
                    padding: "8px 16px",
                    width: isMobile ? "100%" : 200, // Ensure the div takes the full width of the container

                    border: "none",
                    whiteSpace: "nowrap",
                    "&:hover": {
                      backgroundColor: "#FF2C64",
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => handleShowModal()}
                >
                  Dă-ne feedback
                </Button>

                <Typography
                  className="mt-4 mb-4"
                  style={{ fontWeight: "bold", fontFamily: "Poppins" }}
                >
                  Întrebări frecvente
                </Typography>
                <FAQCard
                  header={"Este o platformă de slăbit?"}
                  content={
                    "Nu, platforma noastră nu se concentrează exclusiv pe slăbit. Scopul nostru principal este să te ajutăm să îți atingi obiectivele de sănătate și să îți îmbunătățești starea generală prin alimentație echilibrată, personalizată nevoilor tale. De asemenea, îți poți seta intervalul caloric dorit, astfel încât să fii în deficit și să îți atingi obiectivele de slăbire sau menținere a greutății, în mod sănătos și sustenabil."
                  }
                  expanded={expandedPanel === "panel1"}
                  onChange={handleAccordionChange("panel1")}
                />
                <FAQCard
                  header={"O să primesc meniurile gata preparate acasă?"}
                  content={
                    "Nu livrăm meniuri gata preparate. În schimb, îți oferim planuri de mese personalizate și îți livrăm ingredientele necesare, astfel încât să poți găti acasă mese sănătoase și delicioase."
                  }
                  expanded={expandedPanel === "panel2"}
                  onChange={handleAccordionChange("panel2")}
                />
                <FAQCard
                  header={
                    "Resursele și rețetele sunt verificate de un medic specialist?"
                  }
                  content={
                    "Da, resursele și rețetele noastre sunt verificate de specialiști în nutriție și sănătate, pentru a te asigura că primești informații corecte și eficiente."
                  }
                  expanded={expandedPanel === "panel3"}
                  onChange={handleAccordionChange("panel3")}
                />
                <FAQCard
                  header={"Cum o sa imi fie livrate alimentele?"}
                  content={
                    "Alimentele îți vor fi livrate direct la adresa ta prin intermediul unui partener extern. Mai întâi, vei primi o listă de cumpărături personalizată, pe care o poți modifica după preferințele tale. După ce finalizezi lista, poți comanda ingredientele, iar acestea vor fi livrate prin partenerul nostru (disponibil doar în București)."
                  }
                  expanded={expandedPanel === "panel4"}
                  onChange={handleAccordionChange("panel4")}
                />
                <FAQCard
                  header={
                    "Mă pot ajuta rețetele să îmi îmbunătățesc problema de sănătate?"
                  }
                  content={
                    "Da, rețetele noastre sunt concepute pentru a sprijini îmbunătățirea diferitelor probleme de sănătate, cum ar fi balonarea, problemele hormonale și alte afecțiuni. Planurile de mese sunt personalizate pentru a răspunde nevoilor tale specifice."
                  }
                  expanded={expandedPanel === "panel5"}
                  onChange={handleAccordionChange("panel5")}
                />
                <FAQCard
                  header={"Pot seta un buget pentru cumpărăturile săptămânale?"}
                  content={
                    "Da, platforma noastră îți permite să setezi un buget pentru cumpărăturile săptămânale, astfel încât să poți planifica mesele într-un mod eficient și accesibil."
                  }
                  expanded={expandedPanel === "panel6"}
                  onChange={handleAccordionChange("panel6")}
                />
                <FAQCard
                  header={"Îmi pot face eu cumpărăturile singur ?"}
                  content={
                    "Da, poți să-ți faci cumpărăturile singur. Aplicația noastră îți oferă flexibilitatea de a comanda ingredientele direct din platformă, dar nu ești obligat să faci acest lucru."
                  }
                  expanded={expandedPanel === "panel7"}
                  onChange={handleAccordionChange("panel7")}
                />
              </Col>
            </Row>
          </>
        );
      case "istoric":
        return (
          <>
            <Row className="mt-4">
              <Col lg={6} className="">
                <Typography
                  style={{ fontWeight: "bold", fontFamily: "Poppins" }}
                >
                  Istoricul plăților
                </Typography>
                {payments.map((payment, index) => (
                  <Row key={index} className="mt-4">
                    <Typography
                      style={{ fontFamily: "Poppins", fontWeight: "bold" }}
                    >
                      {payment.amount} RON
                    </Typography>
                    <Typography style={{ fontFamily: "Poppins" }}>
                      Status plata:{" "}
                      {payment.status === "paid" ? (
                        <span style={{ color: "green" }}>Efectuată</span>
                      ) : (
                        <span style={{ color: "red" }}>Neefectuată</span>
                      )}
                    </Typography>
                    <Typography
                      className="mt-4"
                      style={{ fontFamily: "Poppins" }}
                    >
                      Data emitere
                    </Typography>
                    <Stack direction="horizontal">
                      <Typography style={{ fontFamily: "Poppins" }}>
                        {formatDate(payment.created)}
                      </Typography>
                      <Typography
                        className="ms-auto"
                        style={{ fontFamily: "Poppins" }}
                      >
                        {payment.status === "paid" ? (
                          <a
                            style={{ color: "#000" }}
                            href={payment.invoiceUrl}
                            target="_blank"
                          >
                            {" "}
                            Descarca factura
                          </a>
                        ) : (
                          <a
                            style={{ color: "#000", cursor: "pointer" }} // Added cursor for visual indication of clickable
                            onClick={(e) => {
                              e.preventDefault(); // Prevent default behavior of the anchor tag
                              payBill(payment.id); // Call the payment function
                            }}
                            href="#!" // Use an empty link to keep it accessible and semantic
                          >
                            Reincearca plata
                          </a>
                        )}
                      </Typography>
                    </Stack>

                    {/* <StatusCircle status="success" /> */}
                  </Row>
                ))}
              </Col>
            </Row>
          </>
        );

      case "schimba alimente":
        return (
          <>
            <Row className="mt-4">
              <Col lg={6} className="">
                <Typography
                  style={{ fontWeight: "bold", fontFamily: "Poppins" }}
                >
                  Istoricul plăților
                </Typography>
                {payments.map((payment, index) => (
                  <Row key={index} className="mt-4">
                    <Typography
                      style={{ fontFamily: "Poppins", fontWeight: "bold" }}
                    >
                      {payment.amount} RON
                    </Typography>
                    <Typography style={{ fontFamily: "Poppins" }}>
                      Status plata:{" "}
                      {payment.status === "paid" ? (
                        <span style={{ color: "green" }}>Efectuată</span>
                      ) : (
                        <span style={{ color: "red" }}>Neefectuată</span>
                      )}
                    </Typography>
                    <Typography
                      className="mt-4"
                      style={{ fontFamily: "Poppins" }}
                    >
                      Data emitere
                    </Typography>
                    <Stack direction="horizontal">
                      <Typography style={{ fontFamily: "Poppins" }}>
                        {formatDate(payment.created)}
                      </Typography>
                      <Typography
                        className="ms-auto"
                        style={{ fontFamily: "Poppins" }}
                      >
                        {payment.status === "paid" ? (
                          <a
                            style={{ color: "#000" }}
                            href={payment.invoiceUrl}
                            target="_blank"
                          >
                            {" "}
                            Descarca factura
                          </a>
                        ) : (
                          <a
                            style={{ color: "#000", cursor: "pointer" }} // Added cursor for visual indication of clickable
                            onClick={(e) => {
                              e.preventDefault(); // Prevent default behavior of the anchor tag
                              payBill(payment.id); // Call the payment function
                            }}
                            href="#!" // Use an empty link to keep it accessible and semantic
                          >
                            Reincearca plata
                          </a>
                        )}
                      </Typography>
                    </Stack>

                    {/* <StatusCircle status="success" /> */}
                  </Row>
                ))}
              </Col>
            </Row>
          </>
        )
      default:
        return <Typography>setari</Typography>;
    }
  };

  // Styling for active button
  const activeButtonStyle = {
    borderRadius: "22px",
    color: "#DCF552",
    backgroundColor: "#0C3919",
    padding: "12px",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins",
  };

  // Styling for inactive buttons
  const inactiveButtonStyle = {
    borderRadius: "22px",
    border: "1px solid black",
    padding: "12px",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins",
    backgroundColor: "transparent",
    color: "black",
  };
  const isMobile = useMediaQuery("(max-width:1000px)");
  if (isMobile) {
    return (
      <Container
        fluid
        style={{
          width: "100%",
          height: "100vh",
          backgroundColor: "#FFFEF7",
          paddingBottom: 40,
        }}
      >
        <Row>
          <DashboardSidebar />
        </Row>
        <Row>
          <Col
            style={{
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
              backgroundColor: "#FFFEF7",
              paddingTop: "40px",
              paddingBottom: "30px",
              paddingLeft: "40px",
              paddingRight: "40px",
              boxShadow: "10px 5px 5px #f0f0f0",
            }}
          >
            <Stack direction="horizontal" gap={3}>
              <Avatar
                sx={{ bgcolor: "#F3A7BB", width: "60px", height: "60px" }}
              >
                {username.charAt(0)}
              </Avatar>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                {username}
              </Typography>
            </Stack>
          </Col>
        </Row>
        <Row
          style={{ paddingLeft: "20px", paddingRight: "20px" }}
          className="mt-5"
        >
          <Typography
            sx={{ fontFamily: "Poppins", fontWeight: "bold", fontSize: "20px" }}
          >
            Profil
          </Typography>
          <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            <Stack className="mt-2" gap={3} direction="horizontal">
              <Button
                variant="outline-dark"
                style={
                  activeButton === "Setări"
                    ? activeButtonStyle
                    : inactiveButtonStyle
                }
                onClick={() => handleClick("Setări")}
              >
                Setări
              </Button>
              <Button
                variant="outline-dark"
                style={
                  activeButton === "Abonament"
                    ? activeButtonStyle
                    : inactiveButtonStyle
                }
                onClick={() => handleClick("Abonament")}
              >
                Abonament
              </Button>
              <Button
                variant="outline-dark"
                style={
                  activeButton === "Plan"
                    ? activeButtonStyle
                    : inactiveButtonStyle
                }
                onClick={() => handleClick("Plan")}
              >
                Plan
              </Button>
              <Button
                variant="outline-dark"
                style={
                  activeButton === "Help Center"
                    ? activeButtonStyle
                    : inactiveButtonStyle
                }
                onClick={() => handleClick("Help Center")}
              >
                Help Center
              </Button>
            </Stack>
          </div>
        </Row>
        <Row
          className="mt-4"
          style={{ paddingLeft: "20px", paddingRight: "20px" }}
        >
          <Typography sx={{ fontFamily: "Poppins", fontSize: "18px" }}>
            {renderText()}
          </Typography>
        </Row>
      </Container>
    );
  } else {
    return (
      <Container fluid style={{ backgroundColor: "#E7EFDF" }}>
        <Row>
          {/* Lateral Navigation */}
          <Col
            lg={2}
            // xs={2}
            // md={2}
            style={{
              backgroundColor: "#E7EFDF",
              height: "100vh",
              paddingTop: "20px",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-between",
              position: "sticky", // Make it sticky
              top: 0, // Stick to the top
              overflowY: "auto", // Allow vertical scrolling if needed
            }}
          >
            <Typography
              style={{
                fontFamily: "Poppins",
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "#0C3919",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              bloomplate*
            </Typography>
            {/* <Nav className="d-flex flex-column"> */}
            <Stack direction="horizontal" gap={3} className="mt-5">
              <Avatar
                sx={{ bgcolor: "#F3A7BB", width: "40px", height: "40px" }}
              >
                {username.charAt(0)}
              </Avatar>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                {username}
              </Typography>
            </Stack>
            <hr className="px-3 mb-3" style={{ border: "1px solid gray" }} />
            <Button
              className=" p-3 mb-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: "transparent",
                border: "1px solid #000",
                color: "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard");
                setIsDashboardClicked(!isDashboardClicked);
              }}
            >
              <HomeIcon sx={{ marginRight: "20px" }} /> Dashboard
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isCumpClicked ? "#117233" : "transparent",
                border: isCumpClicked ? "none" : "1px solid #000",
                color: isCumpClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard/shopping-list");
                setIsCumpClicked(!isCumpClicked);
              }}
            >
              <ShoppingCartIcon sx={{ marginRight: "20px" }} /> Cumpărături
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: "#117233",
                border: "none",
                color: "#fff",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/profile/settings");
                setIsProfilClicked(!isProfilClicked);
              }}
            >
              <PersonIcon sx={{ marginRight: "20px" }} /> Profil
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isRecommendationsClicked
                  ? "#117233"
                  : "transparent",
                border: isRecommendationsClicked ? "none" : "1px solid #000",
                color: isRecommendationsClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard/recomandari");
                setIsRecommendationsClicked(!isRecommendationsClicked);
              }}
            >
              <AssignmentIcon sx={{ marginRight: "20px" }} /> Recomandări
            </Button>
            {/* </Nav> */}
            <Button
              className="mb-4 mt-auto p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isLogOutClicked ? "#117233" : "transparent",
                border: isLogOutClicked ? "none" : "1px solid #000",
                color: isLogOutClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                logout();
                setIsLogOutClicked(!isLogOutClicked);
              }}
            >
              <ExitToAppIcon sx={{ marginRight: "20px" }} /> Log Out
            </Button>
          </Col>
          <Col lg={10} className="mt-4 mb-4">
            <Row
              style={{
                // padding: 10,
                paddingTop: 20,
                marginLeft: 10,
                paddingLeft: 20,
                paddingRight: 20,
                marginRight: 10,
                paddingBottom: 20,
                borderRadius: "40px", // Rounded corners
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
                backgroundColor: "#FFFEF7",
              }}
            >
              <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: "30px",
                  }}
                >
                  Profil
                </Typography>
                <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                  <Stack className="mt-2" gap={3} direction="horizontal">
                    <Button
                      variant="outline-dark"
                      style={
                        activeButton === "Setări"
                          ? activeButtonStyle
                          : inactiveButtonStyle
                      }
                      onClick={() => handleClick("Setări")}
                    >
                      Setări
                    </Button>
                    <Button
                      variant="outline-dark"
                      style={
                        activeButton === "Abonament"
                          ? activeButtonStyle
                          : inactiveButtonStyle
                      }
                      onClick={() => handleClick("Abonament")}
                    >
                      Abonament
                    </Button>
                    <Button
                      variant="outline-dark"
                      style={
                        activeButton === "Plan"
                          ? activeButtonStyle
                          : inactiveButtonStyle
                      }
                      onClick={() => handleClick("Plan")}
                    >
                      Plan
                    </Button>
                    <Button
                      variant="outline-dark"
                      style={
                        activeButton === "Help Center"
                          ? activeButtonStyle
                          : inactiveButtonStyle
                      }
                      onClick={() => handleClick("Help Center")}
                    >
                      Help Center
                    </Button>
                  </Stack>
                </div>
              </Row>
              <Row
                className="mt-4"
                style={{ paddingLeft: "20px", paddingRight: "20px" }}
              >
                <Typography sx={{ fontFamily: "Poppins", fontSize: "18px" }}>
                  <Row style={{ width: "60%%" }}>{renderText()}</Row>
                </Typography>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default ProfileSettings;
