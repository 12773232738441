import React, { useContext, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  InputGroup,
  Stack, Image
} from "react-bootstrap";
import imagine from "./imagine.jpeg";
import { Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, styled } from "@mui/system";
import Tick from "../../components/buttons/Tick";
import ButtonForm from "../../components/buttons/ButtonForm";
import Back from "../../components/buttons/Back";
import BoxNumber from "../../components/buttons/BoxNumber";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import BoxNumberOutlined from "../../components/buttons/BoxNumberOutlined";
import { useNavigate } from "react-router-dom";
import RegisterState from "../../context/RegisterState";
import logo from "./logoActual2.png";

const Logo = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "bold",
  marginRight: "8px",
});

const QuestionaireMainObjective = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:1000px)");
  const [selectedOption, setSelectedOption] = useState(null); // To track which button is clicked
  const registerState = useContext(RegisterState);
  const [mainObjective, setMainObjective] = useState(
    registerState.mainObjective
  );
  const [validationError, setValidationError] = useState(false); // State for validation

  const handleButtonClick = (option) => {
    setSelectedOption(option);
  };

  const handleSubmit = () => {
    if (mainObjective === "" || selectedOption === null) {
      setValidationError(true); // Trigger error if no selection
      return;
    }
    if (mainObjective === "Energie") {
      registerState.setEnergy(true);
    } else if (mainObjective === "Stres") {
      registerState.setStress(true);
    } else if (mainObjective === "PCOS") {
      registerState.setPcos(true);
    } else if (mainObjective === "Endometrioza") {
      registerState.setEndomethriosis(true);
    } else if (mainObjective === "Balonare") {
      registerState.setBloatingFree(true);
    } else if (mainObjective === "Constipație") {
      registerState.setConstipationFree(true);
    } else if (mainObjective === "Lactoză") {
      registerState.setLactoseFree(true);
    } else if (mainObjective === "Gluten") {
      registerState.setGlutenFree(true);
    }

    console.log(registerState);
    navigate("/questionaire/restrictions");
  };
  return (
    <Container
      fluid
      style={{
        minHeight: "100vh",
        display: "flex",
        padding: 0,
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <Row style={{ flex: "1 1 auto" }}>
        <Col
          className="d-none d-lg-block"
          lg={4}
          xs={12}
          style={{ padding: 0 }}
        >
          <div
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${imagine})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              minHeight: "100vh",
              width: "100%",
              padding: 0,
              fontFamily: "Poppins",
              overflowX: "hidden",
              overflowY: "hidden",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Container
              style={{ marginTop: 80, marginBottom: 40, paddingLeft: 40 }}
            >
              <Row className=" align-items-center text-white">
                <Col lg={10}>
                  {/* <Logo
                    sx={{ color: "#fff", fontFamily: "Poppins" }}
                    variant="h6"
                  >
                    bloomplate*
                  </Logo> */}
                  <Image
                    src={logo}
                    width={"120"}
                    onClick={() => navigate("/")}
                  />

                  <p
                    style={{ fontWeight: 500 }}
                    className="lead mt-5 mb-3 subtitle"
                  >
                    Bloomplate este platforma care simplifică mâncatul sănătos
                    ca tu să poți avea un stil de viață echilibrat.
                  </p>
                  <p className="mt-5" style={{ fontWeight: 300 }}>
                    Cu Bloomplate vei avea:
                  </p>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Energie de dimineață până seara
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          Datorită alimentației sănătoase și echilibrate te vei
                          simții plin de energie și vitalitate în fiecare zi.
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Timp prețios pentru tine
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          O să scapi de stresul planificării meselor zilnice și
                          o să te bucuri de mai mult timp liber.{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Plăcerea de a mânca fără griji
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          Mese delicioase și variate, care se potrivesc perfect
                          gusturilor și nevoilor tale specifice.{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
        <Col lg={8} xs={12} style={{ backgroundColor: "#FFFDF2" }}>
          <Row
            style={{
              marginTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
              boxShadow: "10px 5px 5px #f0f0f0",
            }}
          >
            {!isMobile ? (
              <Stack direction="horizontal" alignItems="center">
                <Back
                  onClick={() => navigate("/questionaire/dietary-preferences")}
                />

                <Box className="ms-auto" display="flex" alignItems="center">
                  <BoxNumberOutlined text={"1"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"2"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumber text={"3"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"4"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"5"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"6"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"7"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <BoxNumberOutlined text={"8"} />

                <ButtonPrimary
                  onClick={() => handleSubmit()}
                  className="ms-auto"
                  text="Continuă"
                />
              </Stack>
            ) : (
              <Stack direction="horizontal" alignItems="center">
                <Box className="ms-auto" display="flex" alignItems="center">
                  <BoxNumberOutlined text={"1"} />
                  <Box sx={{ width: 10, height: "2px", bgcolor: "gray" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"2"} />
                  <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumber text={"3"} />
                  <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"4"} />
                  <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"5"} />
                  <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"6"} />
                  <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"7"} />
                  <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                </Box>

                <BoxNumberOutlined text={"8"} />
              </Stack>
            )}
          </Row>

          <Row
            style={{
              padding: 20,
              marginBottom: "80px",
              marginLeft: !isMobile ? 80 : 0,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#000",
              }}
            >
              Care este principalul tău obiectiv de sănătate?
              {/* sa selectezi maxim 4 alimente */}
            </Typography>

            <Stack className="mt-4">
              <ButtonForm
                width={280}
                text="Stare de bine: energie & stres"
                style={{
                  backgroundColor:
                    selectedOption === "energie_stres"
                      ? "#117233"
                      : "transparent",
                  color: selectedOption === "energie_stres" ? "#fff" : "#000",
                  borderColor:
                    selectedOption === "energie_stres"
                      ? "#117233"
                      : selectedOption === null && validationError
                      ? "red"
                      : "#000",
                }}
                onClick={() => handleButtonClick("energie_stres")}
              />
              <ButtonForm
                width={300}
                className="mt-3"
                text="Hormonal: pcos & endometrioză"
                style={{
                  backgroundColor:
                    selectedOption === "pcos_endometrioza"
                      ? "#117233"
                      : "transparent",
                  color:
                    selectedOption === "pcos_endometrioza" ? "#fff" : "#000",
                  borderColor:
                    selectedOption === "pcos_endometrioza"
                      ? "#117233"
                      : selectedOption === null && validationError
                      ? "red"
                      : "#000",
                }}
                onClick={() => handleButtonClick("pcos_endometrioza")}
              />
              <ButtonForm
                style={{
                  backgroundColor:
                    selectedOption === "balonare_constipatie"
                      ? "#117233"
                      : "transparent",
                  color:
                    selectedOption === "balonare_constipatie" ? "#fff" : "#000",
                  borderColor:
                    selectedOption === "balonare_constipatie"
                      ? "#117233"
                      : selectedOption === null && validationError
                      ? "red"
                      : "#000",
                }}
                width={300}
                className="mt-3"
                text="Digestie: balonare & constipație"
                onClick={() => handleButtonClick("balonare_constipatie")}
              />
              <ButtonForm
                width={280}
                className="mt-3"
                text="Intoleranțe: lactoză & gluten"
                style={{
                  backgroundColor:
                    selectedOption === "lactoza_gluten"
                      ? "#117233"
                      : "transparent",
                  color: selectedOption === "lactoza_gluten" ? "#fff" : "#000",
                  borderColor:
                    selectedOption === "lactoza_gluten"
                      ? "#117233"
                      : selectedOption === null && validationError
                      ? "red"
                      : "#000",
                }}
                onClick={() => handleButtonClick("lactoza_gluten")}
              />
            </Stack>
            {selectedOption !== null && (
              <Typography
                className="mt-2"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "30px",
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                Alege o opțiune
              </Typography>
            )}
            {selectedOption === "energie_stres" && (
              <Stack className="mt-4">
                <ButtonForm
                  width={200}
                  text="Energie"
                  onClick={() => setMainObjective("Energie")}
                  style={{
                    backgroundColor:
                      mainObjective === "Energie" ? "#117233" : "transparent",
                    color: mainObjective === "Energie" ? "#fff" : "#000",
                    borderColor:
                      mainObjective === "Energie"
                        ? "#117233"
                        : mainObjective === "" && validationError
                        ? "red"
                        : "#000",
                  }}
                />
                <ButtonForm
                  width={200}
                  className="mt-3"
                  text="Stres"
                  onClick={() => setMainObjective("Stres")}
                  style={{
                    backgroundColor:
                      mainObjective === "Stres" ? "#117233" : "transparent",
                    color: mainObjective === "Stres" ? "#fff" : "#000",
                    borderColor:
                      mainObjective === "Stres"
                        ? "#117233"
                        : mainObjective === "" && validationError
                        ? "red"
                        : "#000",
                  }}
                />
              </Stack>
            )}
            {selectedOption === "pcos_endometrioza" && (
              <Stack className="mt-4">
                <ButtonForm
                  width={200}
                  text="PCOS"
                  onClick={() => setMainObjective("PCOS")}
                  style={{
                    backgroundColor:
                      mainObjective === "PCOS" ? "#117233" : "transparent",
                    color: mainObjective === "PCOS" ? "#fff" : "#000",
                    borderColor:
                      mainObjective === "PCOS"
                        ? "#117233"
                        : mainObjective === "" && validationError
                        ? "red"
                        : "#000",
                  }}
                />
                <ButtonForm
                  width={200}
                  className="mt-3"
                  text="Endometrioză"
                  onClick={() => setMainObjective("Endometrioza")}
                  style={{
                    backgroundColor:
                      mainObjective === "Endometrioză"
                        ? "#117233"
                        : "transparent",
                    color: mainObjective === "Endometrioza" ? "#fff" : "#000",
                    borderColor:
                      mainObjective === "Endometrioza"
                        ? "#117233"
                        : mainObjective === "" && validationError
                        ? "red"
                        : "#000",
                  }}
                />
              </Stack>
            )}
            {selectedOption === "balonare_constipatie" && (
              <Stack className="mt-4">
                <ButtonForm
                  width={200}
                  text="Balonare"
                  onClick={() => setMainObjective("Balonare")}
                  style={{
                    backgroundColor:
                      mainObjective === "Balonare" ? "#117233" : "transparent",
                    color: mainObjective === "Balonare" ? "#fff" : "#000",
                    borderColor:
                      mainObjective === "Balonare"
                        ? "#117233"
                        : mainObjective === "" && validationError
                        ? "red"
                        : "#000",
                  }}
                />
                <ButtonForm
                  width={200}
                  className="mt-3"
                  text="Constipație"
                  onClick={() => setMainObjective("Constipație")}
                  style={{
                    backgroundColor:
                      mainObjective === "Constipație"
                        ? "#117233"
                        : "transparent",
                    color: mainObjective === "Constipație" ? "#fff" : "#000",
                    borderColor:
                      mainObjective === "Constipație"
                        ? "#117233"
                        : mainObjective === "" && validationError
                        ? "red"
                        : "#000",
                  }}
                />
              </Stack>
            )}
            {selectedOption === "lactoza_gluten" && (
              <Stack className="mt-4">
                <ButtonForm
                  width={200}
                  text="Lactoză"
                  onClick={() => setMainObjective("Lactoză")}
                  style={{
                    backgroundColor:
                      mainObjective === "Lactoză" ? "#117233" : "transparent",
                    color: mainObjective === "Lactoză" ? "#fff" : "#000",
                    borderColor:
                      mainObjective === "Lactoză"
                        ? "#117233"
                        : mainObjective === "" && validationError
                        ? "red"
                        : "#000",
                  }}
                />
                <ButtonForm
                  width={200}
                  className="mt-3"
                  text="Gluten"
                  onClick={() => setMainObjective("Gluten")}
                  style={{
                    backgroundColor:
                      mainObjective === "Gluten" ? "#117233" : "transparent",
                    color: mainObjective === "Gluten" ? "#fff" : "#000",
                    borderColor:
                      mainObjective === "Gluten"
                        ? "#117233"
                        : mainObjective === "" && validationError
                        ? "red"
                        : "#000",
                  }}
                />
              </Stack>
            )}
          </Row>
        </Col>
        {isMobile ? (
          <Row
            style={{
              paddingTop: 10,
              // paddingLeft: 20,
              // paddingRight: 20,
              paddingBottom: 10,
              boxShadow: "0px 5px 5px 10px #f0f0f0",
              backgroundColor: "#FFFDF2",
              position: "fixed",
              bottom: 0,
              width: "100%",
              left: 0,
              right: 0,
              margin: 0,
            }}
          >
            <Stack direction="horizontal" className="footer">
              <Back
                onClick={() => navigate("/questionaire/dietary-preferences")}
              />

              <ButtonPrimary
                onClick={() => handleSubmit()}
                className="ms-auto"
                text="Continuă"
              />
            </Stack>
          </Row>
        ) : (
          <></>
        )}
      </Row>
    </Container>
  );
};

export default QuestionaireMainObjective;
