import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FAQCard from "../../components/cards/FAQCard";
import { Typography } from "@mui/material";
import '../../assets/fonts/fonts.css';

const LandingPageFAQs = () => {
  const [expandedPanel, setExpandedPanel] = useState(null);

  // Handle the accordion toggle
  const handleAccordionChange = (panel) => (_, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null); // Set to null when collapsed
  };
  return (
    <Container id="faq" style={{ marginTop: 80, marginBottom: 40 }}>
      <Row>
        <Col className="mt-4" xs={12} lg={4} md={4}>
          <Typography
            sx={{ fontFamily: "National2Bold", fontSize: 40, fontWeight: "bold" }}
          >
            Curiozități frecvente:
          </Typography>
        </Col>
        <Col className="mt-4" xs={12} lg={8} md={8}>
          <FAQCard
            header={"Este o platformă de slăbit?"}
            content={
              "Nu, platforma noastră nu se concentrează exclusiv pe slăbit. Scopul nostru principal este să te ajutăm să îți atingi obiectivele de sănătate și să îți îmbunătățești starea generală prin alimentație echilibrată, personalizată nevoilor tale. De asemenea, îți poți seta intervalul caloric dorit, astfel încât să fii în deficit și să îți atingi obiectivele de slăbire sau menținere a greutății, în mod sănătos și sustenabil."
            }
            expanded={expandedPanel === "panel1"}
            onChange={handleAccordionChange("panel1")}
          />
          <FAQCard
            header={"O să primesc meniurile gata preparate acasă?"}
            content={
              "Nu livrăm meniuri gata preparate. În schimb, îți oferim planuri de mese personalizate și îți livrăm ingredientele necesare, astfel încât să poți găti acasă mese sănătoase și delicioase."
            }
            expanded={expandedPanel === "panel2"}
            onChange={handleAccordionChange("panel2")}
          />
          <FAQCard
            header={"Resursele și rețetele sunt verificate de un medic specialist?"}
            content={
              "Da, resursele și rețetele noastre sunt verificate de specialiști în nutriție și sănătate, pentru a te asigura că primești informații corecte și eficiente."
            }
            expanded={expandedPanel === "panel3"}
            onChange={handleAccordionChange("panel3")}
          />
          <FAQCard
            header={"Cum o sa imi fie livrate alimentele?"}
            content={
              "Alimentele îți vor fi livrate direct la adresa ta prin intermediul unui partener extern. Mai întâi, vei primi o listă de cumpărături personalizată, pe care o poți modifica după preferințele tale. După ce finalizezi lista, poți comanda ingredientele, iar acestea vor fi livrate prin partenerul nostru (disponibil doar în București)."
            }
            expanded={expandedPanel === "panel4"}
            onChange={handleAccordionChange("panel4")}
          />
          <FAQCard
            header={"Mă pot ajuta rețetele să îmi îmbunătățesc problema de sănătate?"}
            content={
              "Da, rețetele noastre sunt concepute pentru a sprijini îmbunătățirea diferitelor probleme de sănătate, cum ar fi balonarea, problemele hormonale și alte afecțiuni. Planurile de mese sunt personalizate pentru a răspunde nevoilor tale specifice."
            }
            expanded={expandedPanel === "panel5"}
            onChange={handleAccordionChange("panel5")}
          />
          <FAQCard
            header={"Pot seta un buget pentru cumpărăturile săptămânale?"}
            content={
              "Da, platforma noastră îți permite să setezi un buget pentru cumpărăturile săptămânale, astfel încât să poți planifica mesele într-un mod eficient și accesibil."
            }
            expanded={expandedPanel === "panel6"}
            onChange={handleAccordionChange("panel6")}
          />
          <FAQCard
            header={"Îmi pot face eu cumpărăturile singur ?"}
            content={
              "Da, poți să-ți faci cumpărăturile singur. Aplicația noastră îți oferă flexibilitatea de a comanda ingredientele direct din platformă, dar nu ești obligat să faci acest lucru."
            }
            expanded={expandedPanel === "panel7"}
            onChange={handleAccordionChange("panel7")}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default LandingPageFAQs;
