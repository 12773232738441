import { Typography } from "@mui/material";
import React from "react";
import { Container, Row, Table } from "react-bootstrap";
import "./LandingPageTable.css";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import Tick from "../../components/buttons/Tick";
import Exit from "../../components/buttons/Exit";
import picture1 from "./picture1.png";
import picture2 from "./picture2.png";
import picture3 from "./picture3.png";
import picture4 from "./livrare.png";

import Poza2 from "./bloom2.png";
import '../../assets/fonts/fonts.css'
import { useNavigate } from "react-router-dom";

const LandingPageTable = () => {
  const navigate = useNavigate();
  return (
    <Container style={{ marginTop: 160 }}>
      <Row className="text-center">
        <Typography
          sx={{
            fontFamily: "National2Bold",
            fontWeight: 700,
            color: "#000",
            fontSize: 40,
          }}
        >
          Stai...cum adică?
        </Typography>
        <Typography
          className="mt-4 mb-5"
          sx={{
            fontFamily: "Poppins",
            fontWeight: 700,
            color: "#000",
            fontSize: 16,
          }}
        >
          E ok, nici nouă nu ne-a venit să <br/> credem la început.
        </Typography>
      </Row>

      <Row
        style={{ backgroundColor: "transparent" }}
        className="mt-2 text-center"
      >
        <div className="table-responsive">
          <Table borderless hover className="comparison-table">
            <thead>
              <tr>
                <th></th>
                <th>
                  <div className="th-content">
                    <img
                      src={Poza2}
                      alt="Bloomplate"
                      style={{ width: "50px", marginBottom: "5px" }}
                    />
                    <span>Bloomplate</span>
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <img
                      src={picture1}
                      alt="Nutriționiști"
                      style={{ width: "40px", marginBottom: "5px" }}
                    />
                    <span>Nutriționiști</span>
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <img
                      src={picture2}
                      alt="Planning"
                      style={{ width: "50px", marginBottom: "5px" }}
                    />
                    <span>Planning</span>
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <img
                      src={picture3}
                      alt="Cumpărături"
                      style={{ width: "50px", marginBottom: "5px" }}
                    />
                    <span>Cumpărături</span>
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <img
                      src={picture4}
                      alt="Livratori"
                      style={{ width: "70px", marginBottom: "5px" }}
                    />
                    <span>Livratori</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {/** Table Rows **/}
              <tr>
                <td>Planificare personalizată a meselor</td>
                <td>
                  <Tick />
                </td>
                <td>
                  <Tick />
                </td>
                <td>
                  <Tick />
                </td>
                <td>
                  <Exit />
                </td>
                <td>
                  <Exit />
                </td>
              </tr>
              <tr>
                <td>Rețete personalizate bazate pe afecțiuni</td>
                <td>
                  <Tick />
                </td>
                <td>
                  <Tick />
                </td>
                <td>
                  <Exit />
                </td>
                <td>
                  <Exit />
                </td>
                <td>
                  <Exit />
                </td>
              </tr>
              <tr>
                <td>Livrare la domiciliu</td>
                <td>
                  <Tick />
                </td>
                <td>
                  <Exit />
                </td>
                <td>
                  <Exit />
                </td>
                <td>
                  <Exit />
                </td>
                <td>
                  <Tick />
                </td>
              </tr>
              <tr>
                <td>Economisire de timp</td>
                <td>
                  <Tick />
                </td>
                <td>
                  <Tick />
                </td>
                <td>
                  <Exit />
                </td>
                <td>
                  <Exit />
                </td>
                <td>
                  <Tick />
                </td>
              </tr>
              <tr>
                <td>Costuri accesibile</td>
                <td>
                  <Tick />
                </td>
                <td>
                  <Exit />
                </td>
                <td>
                  <Tick />
                </td>
                <td>
                  <Tick />
                </td>
                <td>
                  <Exit />
                </td>
              </tr>
              <tr>
                <td>Ghidare nutrițională</td>
                <td>
                  <Tick />
                </td>
                <td>
                  <Tick />
                </td>
                <td>
                  <Exit />
                </td>
                <td>
                  <Exit />
                </td>
                <td>
                  <Exit />
                </td>
              </tr>
              <tr>
                <td>Diversitate în meniuri</td>
                <td>
                  <Tick />
                </td>
                <td>
                  <Exit />
                </td>
                <td>
                  <Exit />
                </td>
                <td>
                  <Exit />
                </td>
                <td>
                  <Exit />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Row>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 30,
          left: 0,
        }}
      >
        <ButtonPrimary widthSize={200} text="Start chestionar" onClick={() => navigate("/questionaire/age")} />
      </div>
    </Container>
  );
};

export default LandingPageTable;
