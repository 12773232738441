import React, { useContext, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  InputGroup,
  Stack, Image
} from "react-bootstrap";
import imagine from "./imagine.jpeg";
import { Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, styled } from "@mui/system";
import Tick from "../../components/buttons/Tick";
import ButtonForm from "../../components/buttons/ButtonForm";
import Back from "../../components/buttons/Back";
import BoxNumber from "../../components/buttons/BoxNumber";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import BoxNumberOutlined from "../../components/buttons/BoxNumberOutlined";
import { useNavigate } from "react-router-dom";
import RegisterState from "../../context/RegisterState";
import logo from "./logoActual2.png";

const Logo = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "bold",
  marginRight: "8px",
});

const QuestionaireRestrictions = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:1000px)");
  const registerState = useContext(RegisterState);
  const [selectedItems, setSelectedItems] = useState(
    registerState.foodsToBeExcluded
  );
  const [validationError, setValidationError] = useState(false); // State for validation
  const [preferencesDisabled, setPreferencesDisabled] = useState(false); // State to track if preferences are disabled
  const [nam, setNam] = useState(false);
  const handleClick = (item) => {
    if (item === "Nu am preferinte") {
      setPreferencesDisabled(true);
      setNam(true);
      setSelectedItems([]); // Clear any other selections
    } else {
      setNam(false);
      setSelectedItems((prev) => {
        if (prev.includes(item)) {
          // If item is already selected, remove it (deselect)
          return prev.filter((i) => i !== item);
        } else if (prev.length < 4) {
          // If less than 4 items are selected, add the new item
          return [...prev, item];
        } else {
          // Keep the previous state if already 4 items are selected
          return prev;
        }
      });
    }
  };

  const handleSubmit = () => {
    if (selectedItems === "") {
      setValidationError(true);
      return;
    }
    registerState.setFoodsToBeExcluded("aia zic");
    navigate("/questionaire/time");
  };

  const getButtonStyle = (item) => ({
    backgroundColor: selectedItems.includes(item) ? "#117233" : "transparent",
    color: selectedItems.includes(item) ? "#fff" : "#000",
    borderColor: selectedItems.includes(item)
      ? "#117233"
      : selectedItems === "" && validationError
      ? "red"
      : "#000",
  });

  const getNamStyle = (item) => ({
    backgroundColor: nam === true ? "#117233" : "transparent",
    color: nam === true ? "#fff" : "#000",
    borderColor:
      nam === true
        ? "#117233"
        : selectedItems === "" && validationError
        ? "red"
        : "#000",
  });

  return (
    <Container
      fluid
      style={{
        minHeight: "100vh",
        display: "flex",
        padding: 0,
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <Row style={{ flex: "1 1 auto" }}>
        <Col
          className="d-none d-lg-block"
          lg={4}
          xs={12}
          style={{ padding: 0 }}
        >
          <div
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${imagine})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              minHeight: "100vh",
              width: "100%",
              padding: 0,
              fontFamily: "Poppins",
              overflowX: "hidden",
              overflowY: "hidden",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Container
              style={{ marginTop: 80, marginBottom: 40, paddingLeft: 40 }}
            >
              <Row className=" align-items-center text-white">
                <Col lg={10}>
                  {/* <Logo
                    sx={{ color: "#fff", fontFamily: "Poppins" }}
                    variant="h6"
                  >
                    bloomplate*
                  </Logo> */}
                  <Image
                    src={logo}
                    width={"120"}
                    onClick={() => navigate("/")}
                  />

                  <p
                    style={{ fontWeight: 500 }}
                    className="lead mt-5 mb-3 subtitle"
                  >
                    Bloomplate este platforma care simplifică mâncatul sănătos
                    ca tu să poți avea un stil de viață echilibrat.
                  </p>
                  <p className="mt-5" style={{ fontWeight: 300 }}>
                    Cu Bloomplate vei avea:
                  </p>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Energie de dimineață până seara
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          Datorită alimentației sănătoase și echilibrate te vei
                          simții plin de energie și vitalitate în fiecare zi.
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Timp prețios pentru tine
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          O să scapi de stresul planificării meselor zilnice și
                          o să te bucuri de mai mult timp liber.{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    style={{
                      padding: "16px",
                      backgroundColor: "transparent",
                      border: 0,
                      fontFamily: "Poppins",
                    }}
                  >
                    <Row>
                      <Col xs="auto">
                        <Tick />
                      </Col>
                      <Col>
                        <Card.Title
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          Plăcerea de a mânca fără griji
                        </Card.Title>
                        <Card.Text style={{ color: "#fff", fontSize: "12px" }}>
                          Mese delicioase și variate, care se potrivesc perfect
                          gusturilor și nevoilor tale specifice.{" "}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
        <Col lg={8} xs={12} style={{ backgroundColor: "#FFFDF2" }}>
          <Row
            style={{
              marginTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
              boxShadow: "10px 5px 5px #f0f0f0",
            }}
          >
            {!isMobile ? (
              <Stack direction="horizontal" alignItems="center">
                <Back
                  onClick={() => navigate("/questionaire/main-objective")}
                />

                <Box className="ms-auto" display="flex" alignItems="center">
                  <BoxNumberOutlined text={"1"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"2"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"3"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumber text={"4"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"5"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"6"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"7"} />
                  <Box sx={{ width: 50, height: "4px", bgcolor: "#E7E5DC" }} />
                </Box>

                <BoxNumberOutlined text={"8"} />

                <ButtonPrimary
                  onClick={() => handleSubmit()}
                  className="ms-auto"
                  text="Continuă"
                />
              </Stack>
            ) : (
              <Stack direction="horizontal" alignItems="center">
                <Box className="ms-auto" display="flex" alignItems="center">
                  <BoxNumberOutlined text={"1"} />
                  <Box sx={{ width: 10, height: "2px", bgcolor: "gray" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"2"} />
                  <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"3"} />
                  <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumber text={"4"} />
                  <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"5"} />
                  <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"6"} />
                  <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                </Box>

                <Box display="flex" alignItems="center">
                  <BoxNumberOutlined text={"7"} />
                  <Box sx={{ width: 15, height: "2px", bgcolor: "gray" }} />
                </Box>

                <BoxNumberOutlined text={"8"} />
              </Stack>
            )}
          </Row>

          <Row style={{ padding: 20, marginLeft: !isMobile ? 80 : 0 }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "30px",
                fontWeight: "bold",
                color: "#000",
              }}
            >
              Ai alergii alimentare sau restricții dietetice?
            </Typography>

            <Stack className="mt-4" direction="horizontal" gap={2}>
              <ButtonForm
                text="Broccoli"
                style={getButtonStyle("Broccoli")}
                onClick={() => handleClick("Broccoli")}
                disabled={preferencesDisabled}
              />
              <ButtonForm
                text="Conopidă"
                style={getButtonStyle("Conopidă")}
                onClick={() => handleClick("Conopidă")}
              />
              <ButtonForm
                text="Ouă"
                style={getButtonStyle("Ouă")}
                onClick={() => handleClick("Ouă")}
              />
            </Stack>
            <Stack className="mt-2" direction="horizontal" gap={2}>
              <ButtonForm
                text="Pește"
                style={getButtonStyle("Pește")}
                onClick={() => handleClick("Pește")}
              />
              <ButtonForm
                text="Mazăre"
                style={getButtonStyle("Mazăre")}
                onClick={() => handleClick("Mazăre")}
              />
              <ButtonForm
                text="Carne roșie"
                style={getButtonStyle("Carne roșie")}
                onClick={() => handleClick("Carne roșie")}
              />
            </Stack>
            <Stack className="mt-2" direction="horizontal" gap={2}>
              <ButtonForm
                text="Fructe de mare"
                style={getButtonStyle("Fructe de mare")}
                onClick={() => handleClick("Fructe de mare")}
              />
              <ButtonForm
                text="Nuci"
                style={getButtonStyle("Nuci")}
                onClick={() => handleClick("Nuci")}
              />
            </Stack>
            <Stack className="mt-2" direction="horizontal" gap={2}>
              <ButtonForm
                text="Arahide"
                style={getButtonStyle("Arahide")}
                onClick={() => handleClick("Arahide")}
              />
              <ButtonForm
                text="Soia"
                style={getButtonStyle("Soia")}
                onClick={() => handleClick("Soia")}
              />
              <ButtonForm
                text="Tofu"
                style={getButtonStyle("Tofu")}
                onClick={() => handleClick("Tofu")}
              />
            </Stack>
            <Stack className="mt-2" direction="horizontal" gap={2}>
              <ButtonForm
                text="Ficat/ Măruntaie"
                style={getButtonStyle("Ficat/ Măruntaie")}
                onClick={() => handleClick("Ficat/ Măruntaie")}
              />
              <ButtonForm
                text="Măsline"
                style={getButtonStyle("Măsline")}
                onClick={() => handleClick("Măsline")}
              />
            </Stack>
            <Stack className="mt-2" direction="horizontal" gap={2}>
              <ButtonForm
                text="Sparanghel"
                style={getButtonStyle("Sparanghel")}
                onClick={() => handleClick("Sparanghel")}
              />
              <ButtonForm
                text="Varză de bruxelles"
                style={getButtonStyle("Varză de bruxelles")}
                onClick={() => handleClick("Varză de bruxelles")}
              />
            </Stack>

            <Stack className="mt-5" direction="horizontal" gap={2}>
              <ButtonForm
                text="Nu am preferințe"
                style={getNamStyle("Nu am preferinte")}
                onClick={() => handleClick("Nu am preferinte")}
              />
            </Stack>

            <Typography
              className="mt-3"
              sx={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "italic",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              Disclaimer :
            </Typography>
            <Typography
              className="mt-2"
              sx={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "italic",
                color: "#000",
              }}
            >
              Pentru că încă suntem la început, iar acest feature este în curs
              de dezvoltare, facem tot posibilul să îți furnizăm rețete fără
              ingredientele selectate, însă această opțiune va fi disponibilă
              începând cu a doua săptămână de plan.
            </Typography>

            {/* <Typography
              className="mt-2"
              sx={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "italic",
                color: "#000",
              }}
            >
              Poti alege maxim 4 ingrediente.
            </Typography> */}
          </Row>
        </Col>
        {isMobile ? (
          <Row
            style={{
              paddingTop: 10,
              // paddingLeft: 20,
              // paddingRight: 20,
              paddingBottom: 10,
              boxShadow: "0px 5px 5px 10px #f0f0f0",
              backgroundColor: "#FFFDF2",
              position: "fixed",
              bottom: 0,
              width: "100%",
              left: 0,
              right: 0,
              margin: 0,
            }}
          >
            <Stack direction="horizontal" className="footer">
              <Back onClick={() => navigate("/questionaire/main-objective")} />

              <ButtonPrimary
                onClick={() => handleSubmit()}
                className="ms-auto"
                text="Continuă"
              />
            </Stack>
          </Row>
        ) : (
          <></>
        )}
      </Row>
    </Container>
  );
};

export default QuestionaireRestrictions;
