import { Box } from "@mui/system";
import React from "react";
import { Card, Stack, Col, Row } from "react-bootstrap";
import { FaClock, FaLayerGroup } from "react-icons/fa";
// import CachedIcon from "@mui/icons-material/Replay";
import CachedIcon from '@mui/icons-material/Cached';
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from "@mui/material";

const RecipeCard = ({
  isDesktop,
  text,
  description,
  time,
  noIngredients,
  image,
  mealId,
  day,
  meal_type,
  handleDeleteClick,
  dontTruncate = false,
  influencer,
}) => {
  const navigate = useNavigate();

  const handleReplayClick = (event) => {
    event.stopPropagation(); // Prevents triggering the card's onClick
    navigate("/dashboard/meniu-mese", { state: { mealId, day, meal_type } }); // Pass mealId as state
  };

  function truncateDescription(description) {
    const maxLength = 100;
    return description.length > maxLength
      ? description.substring(0, maxLength) + "..."
      : description;
  }

  function truncateDescription2(description) {
    const maxLength = 20;
    return description.length > maxLength
      ? description.substring(0, maxLength) + "..."
      : description;
  }

  if (isDesktop) {
    return (
      <Card
        onClick={() => navigate(`/dashboard/pagina-mancare/${mealId}`)}
        className="rounded-3 mb-2"
        style={{
          backgroundColor: "#FFFEF7",
          fontFamily: "Poppins",
          border: "none",
          boxShadow: "5px 5px 5px 5px #f0f0f0",
          height: "auto",
        }}
      >
        <Row className="g-0">
          <Col xs={10} md={4} className="p-0">
            <Card.Img
              variant="left"
              src={image}
              style={{
                width: "100%",
                height: "auto",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              }}
            />
          </Col>
          <Col
            xs={2}
            md={8}
            className="d-flex flex-column justify-content-between"
          >
            <Card.Body className="p-3 d-flex flex-column">
              <div>
                {isDesktop && (
                  <Card.Title className="fs-6" style={{ fontWeight: "bold" }}>
                    {text}
                  </Card.Title>
                )}
                {isDesktop && dontTruncate === false && (
                  <Card.Text style={{ fontSize: "14px" }}>
                    {truncateDescription(description)}
                  </Card.Text>
                )}
                {isDesktop && dontTruncate === true && (
                  <>
                    <Card.Text style={{ fontSize: "14px" }}>
                      {description}
                    </Card.Text>
                    <Card.Text style={{ fontSize: "14px" }}>
                      {influencer}
                    </Card.Text>
                  </>
                )}
              </div>
              <Stack gap={2} direction="horizontal" className="mt-auto">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FaClock size={12} />
                  <span className="ms-1 fs-7" style={{ fontSize: 12 }}>
                    {time}m
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FaLayerGroup size={12} />
                  <span className="ms-1 fs-7" style={{ fontSize: 12 }}>
                    {noIngredients}
                  </span>
                </div>

                <>
                  <Box
                    className="ms-auto"
                    style={{
                      width: "28px",
                      height: "28px",
                      backgroundColor: "#FF2C64",
                      borderRadius: "14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer", // Makes it clear it's clickable
                    }}
                  >
                    <CloseIcon
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteClick();
                      }}
                      sx={{
                        color: "#fff",
                        fontSize: "16px",
                      }}
                    />
                  </Box>
                  <Box
                    onClick={handleReplayClick} // Added onClick for CachedIcon
                    style={{
                      width: "28px",
                      height: "28px",
                      backgroundColor: "#FF2C64",
                      borderRadius: "14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer", // Makes it clear it's clickable
                    }}
                  >
                    <CachedIcon
                      sx={{
                        color: "#fff",
                        fontSize: "16px",
                      }}
                    />
                  </Box>
                </>
              </Stack>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    );
  } else {
    return (
      <Card
        onClick={() => navigate(`/dashboard/pagina-mancare/${mealId}`)}
        className="rounded-3 mb-2"
        style={{
          backgroundColor: "#FFFEF7",
          fontFamily: "Poppins",
          border: "none",
          boxShadow: "5px 5px 5px 5px #f0f0f0",
          height: "auto",
        }}
      >
        <Row className="g-0">
          <Col xs={12} md={4} className="p-0">
            {" "}
            {/* Removed d-flex to allow image to fill space */}
            <div style={{ position: "relative", width: "100%" }}>
              <Card.Img
                variant="left"
                src={image}
                style={{
                  width: "100%",
                  height: "auto",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              />
              <Box
                style={{
                  position: "absolute", // Position relative to the parent div
                  top: "10px", // Adjust as needed
                  right: "10px", // Adjust as needed
                  width: "32px",
                  height: "32px",
                  backgroundColor: "#FF2C64",
                  borderRadius: "14px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer", // Makes it clear it's clickable
                }}
                onClick={(event) => {
                  event.stopPropagation(); // Prevent click from triggering parent actions
                  handleDeleteClick();
                }}
              >
                <CloseIcon
                  sx={{
                    color: "#fff",
                    fontSize: "18px",
                  }}
                />
              </Box>
            </div>
          </Col>
          <Col
            xs={2}
            md={8}
            className="d-flex flex-column justify-content-between"
          >
            <Card.Body className="p-3 d-flex flex-column">
              <div>
                {isDesktop && (
                  <Card.Title className="fs-6" style={{ fontWeight: "bold" }}>
                    {text}
                  </Card.Title>
                )}
                {isDesktop && dontTruncate === false && (
                  <Card.Text style={{ fontSize: "14px" }}>
                    {truncateDescription(description)}
                  </Card.Text>
                )}
                {isDesktop && dontTruncate === true && (
                  <>
                    <Card.Text style={{ fontSize: "14px" }}>
                      {description}
                    </Card.Text>
                    <Card.Text style={{ fontSize: "14px" }}>
                      {influencer}
                    </Card.Text>
                  </>
                )}
              </div>

              
            </Card.Body>
          </Col>
          
        </Row>
        <Stack style={{marginTop: "-32px"}} className="p-2" direction="horizontal" gap={2}>
            <Typography
              // className="ms-2 mb-2"
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: "Poppins",
              }}
            >
              {text}
            </Typography>
            <Box
              className="ms-auto"
              onClick={handleReplayClick} // Added onClick for CachedIcon
              style={{
                width: "32px",
                height: "32px",
                backgroundColor: "#117233",
                borderRadius: "14px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer", // Makes it clear it's clickable
              }}
            >
              <CachedIcon
                sx={{
                  color: "#fff",
                  fontSize: "18px",
                }}
              />
            </Box>
          </Stack>
      </Card>
    );
  }
};

export default RecipeCard;
