import React from "react";
import Button from "@mui/material/Button";
import { styled, width } from "@mui/system";
import PropTypes from "prop-types";

const StyledButton = styled(Button)(({ onClick, marginleft, widthSize }) => ({
  backgroundColor: "#FF2C64",
  fontFamily: "Poppins",
  fontSize: widthSize ? 20 : 16,
  fontWeight: 400,
  borderRadius: 10,
  textTransform: "none",
  padding: "8px 16px",
  marginLeft: marginleft || 0,
  minWidth: widthSize || 120,
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#FF2C64",
    boxShadow: "none",
  },
}));

const ButtonPrimary = ({ text, marginLeft, marginRight, className, onClick, type, widthSize }) => {
  return (
    <StyledButton
      variant="contained"
      size="medium"
      disableElevation
      marginleft={marginLeft}
      className={className}
      onClick={onClick}
      type={type}
      widthSize={widthSize}
    >
      {text}
    </StyledButton>
  );
};

ButtonPrimary.propTypes = {
  text: PropTypes.string.isRequired,
  marginLeft: PropTypes.string,
  className: PropTypes.string,
};

ButtonPrimary.defaultProps = {
  marginLeft: "0",
  className: "",
};

export default ButtonPrimary;
