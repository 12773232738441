import { Avatar, Skeleton, Typography, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Stack,
  Button,
  Modal,
  FormControl,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import "./DashboardSidebar.css";
import {
  Search as SearchIcon,
  FavoriteBorder as FavoriteBorderIcon,
} from "@mui/icons-material";
import DashboardSidebar from "../../components/navbar/DashboardSidebar";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CachedIcon from "@mui/icons-material/Cached";

import { Box } from "@mui/system";
import { Card } from "react-bootstrap";
import ReplayIcon from "@mui/icons-material/Replay";
import { AuthContext } from "../../context/AuthContext";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";
import RecipeCard from "../../components/cards/RecipeCard";

const RecipeCardTemp = ({
  isDesktop,
  text,
  description,
  image,
  dontTruncate = false,
}) => {
  function truncateDescription(description) {
    const maxLength = 100;
    return description.length > maxLength
      ? description.substring(0, maxLength) + "..."
      : description;
  }
  function truncateDescription2(description) {
    const maxLength = 20;
    return description.length > maxLength
      ? description.substring(0, maxLength) + "..."
      : description;
  }
  return (
    <Card
      className="rounded-3 mb-2"
      style={{
        backgroundColor: "#FFFEF7",
        fontFamily: "Poppins",
        border: "none",
        boxShadow: "5px 5px 5px 5px #f0f0f0",
        height: "auto",
      }}
    >
      <Row className="g-0">
        <Col xs={6} md={4} className="p-0">
          {" "}
          {/* Removed d-flex to allow image to fill space */}
          <Card.Img
            variant="left"
            src={image}
            style={{
              width: "100%",
              height: "auto",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
            }}
          />
        </Col>
        <Col
          xs={6}
          md={8}
          className="d-flex flex-column justify-content-between"
        >
          <Card.Body className="p-3 d-flex flex-column">
            <div>
              {!isDesktop && (
                <Card.Title
                  className="fs-6"
                  style={{ fontWeight: "bold", fontSize: "10px" }}
                >
                  {truncateDescription2(text)}
                  {/* {text} */}
                </Card.Title>
              )}
              {isDesktop && (
                <Card.Title className="fs-6" style={{ fontWeight: "bold" }}>
                  {text}
                </Card.Title>
              )}
              {isDesktop && dontTruncate === false && (
                <Card.Text style={{ fontSize: "14px" }}>
                  {truncateDescription(description)}
                </Card.Text>
              )}
              {isDesktop && dontTruncate === true && (
                <>
                  <Card.Text style={{ fontSize: "14px" }}>
                    {description}
                  </Card.Text>
                </>
              )}
            </div>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

const MeniuMeseCard = ({ id, onClick, image, isFavorite = false, token }) => {
  // State to track if the meal is a favorite
  const [isFavoriteState, setIsFavoriteState] = useState(isFavorite);

  // This function assumes `isFavorite` indicates whether the meal is already in the favorites list
  const addFavorite = async (id, isFavorite, token) => {
    const action = isFavorite ? "remove" : "add";
    const message = isFavorite
      ? "Meal removed from favorites."
      : "Meal added to favorites.";

    try {
      const response = await axios({
        method: "post",
        url: "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
        headers: {
          Authorization: `JWT ${token}`, // Pass JWT token here
          "Content-Type": "application/json",
        },
        data: {
          query: `
            mutation AddRemoveFavoriteMeal($mealId: ID!, $action: String!) {
              addRemove(mealId: $mealId, action: $action) {
                success
                message
              }
            }
          `,
          variables: {
            mealId: id,
            action: action,
          },
        },
      });

      // Handle response
      const { success, message: responseMessage } =
        response.data.data.addRemove;
      console.log(responseMessage);
      if (success) {
        // Update the state to toggle the icon
        setIsFavoriteState((prev) => !prev); // Toggle the `isFavorite` state
      }
    } catch (error) {
      console.error("Error while updating favorite meal:", error);
      // Handle error appropriately
    }
  };

  const isMobile = useMediaQuery("(max-width:1000px)");
  let width = "100%";
  if (!isMobile) {
    width = "200px";
  }

  return (
    <Card
      onClick={onClick}
      className="text-center shadow-sm"
      style={{
        minHeight: "200px",
        width: width,
        borderRadius: "15px",
        border: "none",
        overflow: "hidden",
      }}
    >
      <Card.Body
        style={{
          position: "relative",
          padding: "10px",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
        }}
      >
        <Stack direction="horizontal" gap={2} style={{ float: "right" }}>
          <Box
            sx={{
              width: "32px",
              height: "32px",
              backgroundColor: "#fff",
              borderRadius: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation(); // Prevent click from bubbling to the parent
              addFavorite(id, isFavoriteState, token);
            }}
          >
            {isFavoriteState ? (
              <FavoriteIcon sx={{ color: "#FF2C64" }} />
            ) : (
              <FavoriteBorderIcon sx={{ color: "#FF2C64" }} />
            )}
          </Box>
          <Box
            className="ms-auto"
            sx={{
              width: "32px",
              height: "32px",
              backgroundColor: "#FF2C64",
              borderRadius: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
            // onClick={onClickModal}
          >
            <CachedIcon sx={{ color: "#fff" }} />
          </Box>
        </Stack>
      </Card.Body>
    </Card>
  );
};

const DashboardMeniuMese = ({}) => {
  const { logout } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [isDashboardClicked, setIsDashboardClicked] = useState(false);
  const [isCumpClicked, setIsCumpClicked] = useState(false);
  const [isProfilClicked, setIsProfilClicked] = useState(false);
  const [isLogOutClicked, setIsLogOutClicked] = useState(false);
  const [mealsLoading, setMealsLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false); // To track if the button is clicked
  const [loadingMealId, setLoadingMealId] = useState(null);

  const [isRecommendationsClicked, setIsRecommendationsClicked] =
    useState(false);
  const location = useLocation();
  const { mealId } = location.state || 0;
  const { day } = location.state || 0;
  const { meal_type } = location.state || 0;
  const [modalMealOpen, setModalMealOpen] = useState(false);
  const [currentMeal, setCurrentMeal] = useState({});
  const isMobile = useMediaQuery("(max-width:1000px)");
  const token = Cookies.get("authToken");
  const [meals, setMeals] = useState([]);
  const [filteredMeals, setFilteredMeals] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);
  const navigate = useNavigate();

  const handleClick = async () => {
    setIsClicked(!isClicked);
    console.log("Button clicked");
    if (!isClicked) {
      // setIsClicked(true); // Set the button to clicked state
      // setIsClicked((prevState) => !prevState);
      setIsClicked(true); // Set the button to clicked state
      const query = `
      query {
        favoriteMeals {
          id
            title
            imageUrl
            kcal
            proteins
            carbohydrates
            fats
            fibers
            isFavorite
        }
      }
    `;
      console.log("Button clicked");
      try {
        const response = await axios.post(
          "https://bloomplate-backend-990735649563.herokuapp.com/graphql/", // Replace with your actual GraphQL endpoint
          {
            query: query,
          },
          {
            headers: {
              Authorization: `JWT ${token}`, // Assuming token is stored in localStorage
              "Content-Type": "application/json",
            },
          }
        );

        // Handle response data
        if (response.data.errors) {
          throw new Error("Error fetching favorite meals");
        }

        setFilteredMeals(response.data.data.favoriteMeals); // Set meals state with the response data
      } catch (err) {
        // setError(err.message); // Set error message if something goes wrong
      }
    } else {
      // Handle button already clicked
      setIsClicked(false); // Reset the button state

      setFilteredMeals(filteredMeals);
    }
  };

  function renderMealType(mealType) {
    if (mealType.includes("mic dejun")) {
      return "Mic Dejun";
    } else if (mealType.includes("pranz")) {
      return "Prânz";
    } else if (mealType.includes("cina")) {
      return "Cină";
    }
    return mealType; // Return original if no matches found
  }

  function replaceMeal(oldMealId, newMealData, selectedDay, mealType) {
    // Step 1: Retrieve cached data from sessionStorage
    const cachedData =
      JSON.parse(sessionStorage.getItem("combinedUserData")) || {};

    // Step 2: Access meals for the selected day
    const mealsForDay =
      JSON.parse(cachedData.weekData.mealsForWeek)[selectedDay] || {};

    // Step 3: Check if the meal type category exists
    if (mealsForDay[mealType]) {
      // Step 4: Iterate through the meals in the specified meal type to find the meal by oldMealId
      if (mealsForDay[mealType].id === oldMealId) {
        // Replace the old meal's data with new meal data, keeping the old ID
        mealsForDay[mealType] = {
          ...mealsForDay[mealType], // Retain existing properties
          ...newMealData, // Spread the new meal data to overwrite
        };
      }
    } else {
      console.log("Meal type does not exist for the selected day.");
    }

    // Step 5: Update the cachedData with the modified mealsForDay
    cachedData.weekData.mealsForWeek = JSON.stringify({
      ...JSON.parse(cachedData.weekData.mealsForWeek),
      [selectedDay]: mealsForDay,
    });

    // Step 6: Store the updated cachedData back in sessionStorage
    sessionStorage.setItem("combinedUserData", JSON.stringify(cachedData));
  }

  const handleReplace = (newMealId) => {
    setLoadingMealId(newMealId);
    setModalMealOpen(false)

    let finalMealType = "";
    if (meal_type === "mic dejun") {
      finalMealType = "breakfast";
    } else if (meal_type === "pranz") {
      finalMealType = "lunch";
    } else if (meal_type === "cina") {
      finalMealType = "dinner";
    }
    let data = {
      old_meal_id: mealId,
      new_meal_id: newMealId,
      day: day,
      meal_type: finalMealType,
    };
    const query = `
        mutation UpdateMealList($oldMealId: ID!, $newMealId: ID!, $day: Int!, $mealType: String!) {
            updateMealList(oldMealId: $oldMealId, newMealId: $newMealId, day: $day, mealType: $mealType) {
                success
                message
                title
                summary
                imageUrl
                readyInMinutes
                ingredientsCount
            }
        }
    `;

    const variables = {
      oldMealId: mealId,
      newMealId: newMealId,
      day: day,
      mealType: finalMealType,
    };

    axios
      .post(
        "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
        { query, variables },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((response) => {
        const { success, error } = response.data.data.updateMealList;

        if (success) {
          const newMeal = {
            id: newMealId,
            summary: response.data.data.updateMealList.summary,
            title: response.data.data.updateMealList.title,
            image_url: response.data.data.updateMealList.imageUrl,
            ready_in_minutes: response.data.data.updateMealList.readyInMinutes,
            ingredients_count:
              response.data.data.updateMealList.ingredientsCount,
          };
          replaceMeal(mealId, newMeal, day, meal_type);
          navigate("/dashboard");
        } else {
          console.error("Error adding meal:", error);
        }
      })
      .catch((error) => {
        console.error("Error fetching meals:", error);
      })
      .finally(() => {
        setLoadingMealId(null);
      });
  };
  const removeDiacritics = (text) => {
    return text
      .normalize("NFD") // Normalize to decompose characters with accents
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritical marks
      .toLowerCase(); // Convert to lowercase
  };

  // Use the function in the filtering logic
  useEffect(() => {
    const results = meals.filter((meal) => {
      const normalizedTitle = removeDiacritics(meal.title);
      const normalizedSearchTerm = removeDiacritics(searchTerm);
      return normalizedTitle.includes(normalizedSearchTerm);
    });
    setFilteredMeals(results);
  }, [searchTerm, meals]);
  useEffect(() => {
    let mealTypeUrl = "";
    if (meal_type === "breakfast") {
      mealTypeUrl = "mic dejun";
    } else if (meal_type === "lunch") {
      mealTypeUrl = "pranz";
    } else if (meal_type === "dinner") {
      mealTypeUrl = "cina";
    } else if (meal_type === "snacks") {
      mealTypeUrl = "snacks";
    } else {
      mealTypeUrl = meal_type;
    }
    const fetchMeals = async (day, mealTypeUrl) => {
      setMealsLoading(true);
      const query = `
        query($day: Int!, $mealType: String!) {
          mealsNotInMealList(day: $day, mealType: $mealType) {
            meals {
              id
              title
              imageUrl
              kcal
              proteins
              carbohydrates
              fats
              fibers
              isFavorite
            }
            username
          }
        }
      `;

      const variables = {
        day: day,
        mealType: mealTypeUrl,
      };

      try {
        const response = await axios.post(
          "https://bloomplate-backend-990735649563.herokuapp.com/graphql/",
          {
            query, // Send the query here
            variables, // Send the variables here
          },
          {
            headers: {
              Authorization: `JWT ${token}`,
            },
          }
        );

        // Extract meals and username from response
        if (response.data.data && response.data.data.mealsNotInMealList) {
          const { meals, username } = response.data.data.mealsNotInMealList;
          setMeals(meals);
          setFilteredMeals(meals);
          setUsername(username);
        } else {
          console.error("Invalid response structure:", response.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setMealsLoading(false); // Ensure loading state is reset
      }
    };

    // Ensure that day is defined and valid before calling fetchMeals
    if (day) {
      fetchMeals(day, mealTypeUrl);
    }
  }, [day, meal_type, token]);

  if (isMobile) {
    return (
      <Container fluid style={{ width: "100%", backgroundColor: "#FFFDF2" }}>
        <Row>
          <DashboardSidebar isBack={true} />
        </Row>

        <Row>
          <Col
            style={{
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
              backgroundColor: "#FFFDF2",
              paddingTop: "40px",
              paddingBottom: "40px",
              paddingLeft: "40px",
              paddingRight: "40px",
              boxShadow: "10px 5px 5px #f0f0f0",
            }}
          >
            <Typography
              sx={{
                fontSize: "26px",
                fontFamily: "Poppins",
                fontWeight: "bold",
              }}
            >
              Meniu Mese
            </Typography>

            <Row className="mt-2">
              {/* <div style={{ background: "transparent" }}> */}
              <InputGroup
                style={{
                  borderRadius: "20px",
                  border: "1px solid black",
                  overflow: "hidden",
                }}
              >
                <FormControl
                  type="text"
                  placeholder="Caută"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{
                    padding: "10px",
                    border: "none",
                    boxShadow: "none",
                    background: "transparent",
                  }}
                />
                <InputGroup.Text
                  style={{
                    background: "transparent",
                    border: "none",
                    borderLeft: "1px solid black",
                    cursor: "pointer",
                  }}
                >
                  <SearchIcon style={{ fontSize: "16px", color: "black" }} />
                </InputGroup.Text>
              </InputGroup>
              {/* </div> */}
            </Row>
            <Row className="mt-2" style={{ maxWidth: "100px" }}>
              <Button
                variant="outline-dark"
                style={{
                  borderRadius: "20px",
                  border: "1px solid black",
                  padding: "4px 8px",
                  fontSize: "12px",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: isClicked ? "#FF2C64" : "transparent", // Change color when clicked
                }}
                onClick={() => {
                  // setIsClicked(!isClicked);
                  handleClick();
                }}
                // disabled={isClicked} // Disable button after click to prevent multiple requests
              >
                {isClicked ? (
                  <FavoriteIcon
                    style={{ fontSize: "16px", marginRight: "4px" }}
                  />
                ) : (
                  <FavoriteBorderIcon
                    style={{ fontSize: "16px", marginRight: "4px" }}
                  />
                )}
                Preferate
              </Button>
            </Row>
          </Col>
        </Row>
        <Row style={{ padding: "20px" }} className="mt-2">
          <Typography
            sx={{ fontSize: "26px", fontFamily: "Poppins", fontWeight: "bold" }}
          >
            {renderMealType(meal_type)}
          </Typography>
          <Typography sx={{ fontSize: "16px", fontFamily: "Poppins" }}>
            Recomandări pentru săptămâna asta:
          </Typography>
        </Row>
        <Row className="mt-2">
          <Modal
            show={modalMealOpen}
            onHide={() => setModalMealOpen(false)}
            centered
          >
            <Modal.Body>
              <RecipeCardTemp
                dontTruncate={true}
                text={currentMeal.title}
                description={currentMeal.description}
                image={currentMeal.imageUrl}
                isDesktop={true}
              />
              {/* <Typography style={{ fontFamily: "Poppins", fontSize: "20px" }}>
                Informații nutriționale
              </Typography>
              <Typography
                className="mt-2"
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Carbs : {currentMeal.carbohydrates}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Proteins : {currentMeal.proteins}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Fats : {currentMeal.fats}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Fibers : {currentMeal.fibers}
              </Typography> */}
              <Card
                style={{
                  fontFamily: "Poppins",
                  // width: "400px",
                  maxHeight: "500px",
                  overflowY: "auto",
                  backgroundColor: "#FFFEF7",
                }}
                className="shadow-sm mt-4 p-3"
              >
                <Card.Title style={{ fontWeight: "bold" }}>
                  Informații nutriționale
                </Card.Title>
                <Card.Body>
                  <Typography
                    className="mt-5"
                    style={{ fontFamily: "Poppins", fontWeight: "bold" }}
                  >
                    Nutriție per porție
                  </Typography>
                  <hr style={{ border: "1px solid #CFCFCF" }} />
                  <Stack direction="horizontal" gap={2}>
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                    >
                      Calorii
                    </Typography>
                    <Typography
                      className="ms-auto"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "12px",
                      }}
                    >
                      {currentMeal.kcal} kcal
                    </Typography>
                  </Stack>
                  <hr style={{ border: "1px solid #CFCFCF" }} />
                  <Stack direction="horizontal" gap={2}>
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                    >
                      Carbohidrați
                    </Typography>
                    <Typography
                      className="ms-auto"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "12px",
                      }}
                    >
                      {currentMeal.carbohydrates} g
                    </Typography>
                  </Stack>
                  <Stack direction="horizontal" gap={2}>
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: "12px",
                        color: "#797979",
                      }}
                    >
                      Fibre
                    </Typography>
                    <Typography
                      className="ms-auto"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#797979",
                      }}
                    >
                      {currentMeal.fibers} g
                    </Typography>
                  </Stack>
                  <hr style={{ border: "1px solid #CFCFCF" }} />
                  <Stack direction="horizontal" gap={2}>
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                    >
                      Proteine
                    </Typography>
                    <Typography
                      className="ms-auto"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "12px",
                      }}
                    >
                      {currentMeal.proteins} g
                    </Typography>
                  </Stack>
                  <hr style={{ border: "1px solid #CFCFCF" }} />
                  <Stack direction="horizontal" gap={2}>
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                    >
                      Grăsimi
                    </Typography>
                    <Typography
                      className="ms-auto"
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "12px",
                      }}
                    >
                      {currentMeal.fats} g
                    </Typography>
                  </Stack>
                </Card.Body>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <ButtonPrimary
                onClick={() => handleReplace(currentMeal.id)}
                text="Inlocuieste"
              />
            </Modal.Footer>
          </Modal>
          {mealsLoading ? (
            <Row className="text-center">
              <Col className="mb-4" lg={3} xs={6}>
                <Card
                  className="text-center shadow-sm"
                  style={{
                    minHeight: "200px",
                    borderRadius: "15px",
                    border: "none",
                    overflow: "hidden",
                  }}
                >
                  <Card.Body
                    style={{
                      position: "relative",
                      padding: "10px",
                      backgroundColor: "#f0f0f0", // Placeholder color
                      color: "white",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      height="150px"
                      style={{ marginBottom: "10px" }}
                    />

                    <Stack
                      direction="horizontal"
                      gap={2}
                      style={{ float: "right" }}
                    >
                      <Box
                        sx={{
                          width: "32px",
                          height: "32px",
                          backgroundColor: "#fff",
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "auto",
                        }}
                      >
                        <Skeleton variant="circular" width={32} height={32} />
                      </Box>
                      <Box
                        className="ms-auto"
                        sx={{
                          width: "32px",
                          height: "32px",
                          backgroundColor: "#FF2C64",
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "auto",
                        }}
                      >
                        <Skeleton variant="circular" width={32} height={32} />
                      </Box>
                    </Stack>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="mb-4" lg={3} xs={6}>
                <Card
                  className="text-center shadow-sm"
                  style={{
                    minHeight: "200px",
                    // width: ,
                    borderRadius: "15px",
                    border: "none",
                    overflow: "hidden",
                  }}
                >
                  <Card.Body
                    style={{
                      position: "relative",
                      padding: "10px",
                      backgroundColor: "#f0f0f0", // Placeholder color
                      color: "white",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      height="150px"
                      style={{ marginBottom: "10px" }}
                    />

                    <Stack
                      direction="horizontal"
                      gap={2}
                      style={{ float: "right" }}
                    >
                      <Box
                        sx={{
                          width: "32px",
                          height: "32px",
                          backgroundColor: "#fff",
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "auto",
                        }}
                      >
                        <Skeleton variant="circular" width={32} height={32} />
                      </Box>
                      <Box
                        className="ms-auto"
                        sx={{
                          width: "32px",
                          height: "32px",
                          backgroundColor: "#FF2C64",
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "auto",
                        }}
                      >
                        <Skeleton variant="circular" width={32} height={32} />
                      </Box>
                    </Stack>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="mb-4" lg={3} xs={6}>
                <Card
                  className="text-center shadow-sm"
                  style={{
                    minHeight: "200px",
                    // width: ,
                    borderRadius: "15px",
                    border: "none",
                    overflow: "hidden",
                  }}
                >
                  <Card.Body
                    style={{
                      position: "relative",
                      padding: "10px",
                      backgroundColor: "#f0f0f0", // Placeholder color
                      color: "white",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      height="150px"
                      style={{ marginBottom: "10px" }}
                    />

                    <Stack
                      direction="horizontal"
                      gap={2}
                      style={{ float: "right" }}
                    >
                      <Box
                        sx={{
                          width: "32px",
                          height: "32px",
                          backgroundColor: "#fff",
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "auto",
                        }}
                      >
                        <Skeleton variant="circular" width={32} height={32} />
                      </Box>
                      <Box
                        className="ms-auto"
                        sx={{
                          width: "32px",
                          height: "32px",
                          backgroundColor: "#FF2C64",
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "auto",
                        }}
                      >
                        <Skeleton variant="circular" width={32} height={32} />
                      </Box>
                    </Stack>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="mb-4" lg={3} xs={6}>
                <Card
                  className="text-center shadow-sm"
                  style={{
                    minHeight: "200px",
                    // width: ,
                    borderRadius: "15px",
                    border: "none",
                    overflow: "hidden",
                  }}
                >
                  <Card.Body
                    style={{
                      position: "relative",
                      padding: "10px",
                      backgroundColor: "#f0f0f0", // Placeholder color
                      color: "white",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      height="150px"
                      style={{ marginBottom: "10px" }}
                    />

                    <Stack
                      direction="horizontal"
                      gap={2}
                      style={{ float: "right" }}
                    >
                      <Box
                        sx={{
                          width: "32px",
                          height: "32px",
                          backgroundColor: "#fff",
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "auto",
                        }}
                      >
                        <Skeleton variant="circular" width={32} height={32} />
                      </Box>
                      <Box
                        className="ms-auto"
                        sx={{
                          width: "32px",
                          height: "32px",
                          backgroundColor: "#FF2C64",
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "auto",
                        }}
                      >
                        <Skeleton variant="circular" width={32} height={32} />
                      </Box>
                    </Stack>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : (
            filteredMeals.map((meal, index) => (
              <Col className="mb-5" lg={2} xs={6} key={index}>
                {loadingMealId === meal.id ? (
                  <div className="text-center mt-2">
                    <Spinner style={{ color: "#FF2C64" }} />
                    <Typography
                      className="mt-2"
                      style={{ fontFamily: "Poppins" }}
                    >
                      Se înlocuiește rețeta...
                    </Typography>
                  </div>
                ) : (
                  <>
                    <MeniuMeseCard
                      token={token}
                      id={meal.id}
                      isFavorite={meal.isFavorite}
                      image={meal.imageUrl}
                      // onClick={() => handleReplace(meal.id)}
                      onClick={() => {
                        setModalMealOpen(true);
                        setCurrentMeal(meal);
                      }}
                    />
                    <Typography
                      className="mt-2"
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        height: "60px", // Set a fixed height for consistent spacing
                        overflow: "hidden", // Optional: hides any overflow content
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center", // Centers the text vertically
                      }}
                    >
                      {meal.title ? (
                        <>
                          {meal.title} <br /> {meal.description}
                        </>
                      ) : (
                        "Aici o să vină titlul unei rețete"
                      )}
                    </Typography>
                  </>
                )}
              </Col>
            ))
          )}
        </Row>
      </Container>
    );
  } else {
    return (
      <Container
        fluid
        style={{
          backgroundColor: "#E7EFDF",
        }}
      >
        {" "}
        <Row>
          {/* Lateral Navigation */}
          <Col
            lg={2}
            // xs={2}
            // md={2}
            style={{
              backgroundColor: "#E7EFDF",
              height: "100vh",
              paddingTop: "20px",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-between",
              position: "sticky", // Make it sticky
              top: 0, // Stick to the top
              overflowY: "auto", // Allow vertical scrolling if needed
            }}
          >
            <Typography
              onClick={() => {
                navigate("/");
              }}
              style={{
                fontFamily: "Poppins",
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "#0C3919",
                cursor: "pointer",
              }}
            >
              bloomplate*
            </Typography>
            {/* <Nav className="d-flex flex-column"> */}
            <Stack direction="horizontal" gap={3} className="mt-5">
              <Avatar
                sx={{ bgcolor: "#F3A7BB", width: "40px", height: "40px" }}
              >
                {username.charAt(0)}
              </Avatar>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                {username}
              </Typography>
            </Stack>
            <hr className="px-3 mb-3" style={{ border: "1px solid gray" }} />
            <Button
              className="p-3 mb-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: "#117233",
                border: "none",
                color: "#fff",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard");
                setIsDashboardClicked(!isDashboardClicked);
              }}
            >
              <HomeIcon sx={{ marginRight: "20px" }} /> Dashboard
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isCumpClicked ? "#117233" : "transparent",
                border: isCumpClicked ? "none" : "1px solid #000",
                color: isCumpClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard/shopping-list");
                setIsCumpClicked(!isCumpClicked);
              }}
            >
              <ShoppingCartIcon sx={{ marginRight: "20px" }} /> Cumpărături
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isProfilClicked ? "#117233" : "transparent",
                border: isProfilClicked ? "none" : "1px solid #000",
                color: isProfilClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/profile/settings");
                setIsProfilClicked(!isProfilClicked);
              }}
            >
              <PersonIcon sx={{ marginRight: "20px" }} /> Profil
            </Button>
            <Button
              className="mb-3 p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isRecommendationsClicked
                  ? "#117233"
                  : "transparent",
                border: isRecommendationsClicked ? "none" : "1px solid #000",
                color: isRecommendationsClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                navigate("/dashboard/recomandari");
                setIsRecommendationsClicked(!isRecommendationsClicked);
              }}
            >
              <AssignmentIcon sx={{ marginRight: "20px" }} /> Recomandări
            </Button>
            {/* </Nav> */}
            <Button
              className="mb-4 mt-auto p-3"
              variant="light"
              style={{
                textAlign: "left",
                backgroundColor: isLogOutClicked ? "#117233" : "transparent",
                border: isLogOutClicked ? "none" : "1px solid #000",
                color: isLogOutClicked ? "#fff" : "#000",
                paddingLeft: "15px",
                fontFamily: "Poppins",
                fontWeight: 500,
                borderRadius: 10,
              }}
              onClick={() => {
                logout();
                setIsLogOutClicked(!isLogOutClicked);
              }}
            >
              <ExitToAppIcon sx={{ marginRight: "20px" }} /> Log Out
            </Button>
          </Col>

          <Col lg={10} className="mt-4 mb-4">
            <Row
              style={{
                // padding: 10,
                paddingTop: 20,
                marginLeft: 10,
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 20,
                marginRight: 10,
                borderRadius: "40px", // Rounded corners
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
                backgroundColor: "#FFFEF7",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "30px",
                  fontWeight: "bold",
                }}
              >
                Meniu Mese
              </Typography>

              <Stack className="mt-2 mb-5" direction="horizontal" gap={2}>
                <InputGroup
                  style={{
                    borderRadius: "20px",
                    border: "1px solid black",
                    overflow: "hidden",
                  }}
                >
                  <FormControl
                    type="text"
                    placeholder="Caută"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{
                      padding: "10px",
                      border: "none",
                      boxShadow: "none",
                      background: "transparent",
                    }}
                  />
                  <InputGroup.Text
                    style={{
                      background: "transparent",
                      border: "none",
                      borderLeft: "1px solid black",
                      cursor: "pointer",
                    }}
                  >
                    <SearchIcon style={{ fontSize: "16px", color: "black" }} />
                  </InputGroup.Text>
                </InputGroup>

                {/* <Button
                  variant="outline-dark"
                  style={{
                    borderRadius: "20px",
                    border: "1px solid black",
                    padding: "4px 8px",
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FavoriteBorderIcon
                    style={{ fontSize: "16px", marginRight: "4px" }}
                  />
                  Preferate
                </Button> */}
              </Stack>
              <Row className="mt-2 mb-2">
                <Typography
                  sx={{
                    fontSize: "26px",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  {renderMealType(meal_type)}
                </Typography>
                <Typography sx={{ fontSize: "16px", fontFamily: "Poppins" }}>
                  Recomandări pentru săptămâna asta:
                </Typography>
              </Row>
              <Row>
                {mealsLoading ? (
                  <Row className="text-center">
                    <Col className="mb-4" lg={3} xs={6}>
                      <Card
                        className="text-center shadow-sm"
                        style={{
                          minHeight: "200px",
                          borderRadius: "15px",
                          border: "none",
                          overflow: "hidden",
                        }}
                      >
                        <Card.Body
                          style={{
                            position: "relative",
                            padding: "10px",
                            backgroundColor: "#f0f0f0", // Placeholder color
                            color: "white",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            height="150px"
                            style={{ marginBottom: "10px" }}
                          />

                          <Stack
                            direction="horizontal"
                            gap={2}
                            style={{ float: "right" }}
                          >
                            <Box
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#fff",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                              }}
                            >
                              <Skeleton
                                variant="circular"
                                width={32}
                                height={32}
                              />
                            </Box>
                            <Box
                              className="ms-auto"
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#FF2C64",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                              }}
                            >
                              <Skeleton
                                variant="circular"
                                width={32}
                                height={32}
                              />
                            </Box>
                          </Stack>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col className="mb-4" lg={3} xs={6}>
                      <Card
                        className="text-center shadow-sm"
                        style={{
                          minHeight: "200px",
                          // width: ,
                          borderRadius: "15px",
                          border: "none",
                          overflow: "hidden",
                        }}
                      >
                        <Card.Body
                          style={{
                            position: "relative",
                            padding: "10px",
                            backgroundColor: "#f0f0f0", // Placeholder color
                            color: "white",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            height="150px"
                            style={{ marginBottom: "10px" }}
                          />

                          <Stack
                            direction="horizontal"
                            gap={2}
                            style={{ float: "right" }}
                          >
                            <Box
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#fff",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                              }}
                            >
                              <Skeleton
                                variant="circular"
                                width={32}
                                height={32}
                              />
                            </Box>
                            <Box
                              className="ms-auto"
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#FF2C64",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                              }}
                            >
                              <Skeleton
                                variant="circular"
                                width={32}
                                height={32}
                              />
                            </Box>
                          </Stack>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col className="mb-4" lg={3} xs={6}>
                      <Card
                        className="text-center shadow-sm"
                        style={{
                          minHeight: "200px",
                          // width: ,
                          borderRadius: "15px",
                          border: "none",
                          overflow: "hidden",
                        }}
                      >
                        <Card.Body
                          style={{
                            position: "relative",
                            padding: "10px",
                            backgroundColor: "#f0f0f0", // Placeholder color
                            color: "white",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            height="150px"
                            style={{ marginBottom: "10px" }}
                          />

                          <Stack
                            direction="horizontal"
                            gap={2}
                            style={{ float: "right" }}
                          >
                            <Box
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#fff",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                              }}
                            >
                              <Skeleton
                                variant="circular"
                                width={32}
                                height={32}
                              />
                            </Box>
                            <Box
                              className="ms-auto"
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#FF2C64",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                              }}
                            >
                              <Skeleton
                                variant="circular"
                                width={32}
                                height={32}
                              />
                            </Box>
                          </Stack>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col className="mb-4" lg={3} xs={6}>
                      <Card
                        className="text-center shadow-sm"
                        style={{
                          minHeight: "200px",
                          // width: ,
                          borderRadius: "15px",
                          border: "none",
                          overflow: "hidden",
                        }}
                      >
                        <Card.Body
                          style={{
                            position: "relative",
                            padding: "10px",
                            backgroundColor: "#f0f0f0", // Placeholder color
                            color: "white",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            height="150px"
                            style={{ marginBottom: "10px" }}
                          />

                          <Stack
                            direction="horizontal"
                            gap={2}
                            style={{ float: "right" }}
                          >
                            <Box
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#fff",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                              }}
                            >
                              <Skeleton
                                variant="circular"
                                width={32}
                                height={32}
                              />
                            </Box>
                            <Box
                              className="ms-auto"
                              sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: "#FF2C64",
                                borderRadius: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                              }}
                            >
                              <Skeleton
                                variant="circular"
                                width={32}
                                height={32}
                              />
                            </Box>
                          </Stack>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                ) : (
                  filteredMeals.map((meal, index) => (
                    <Col lg={3} className="mb-5">
                      <div key={index}>
                        {loadingMealId === meal.id ? (
                          <div className="text-center mt-2">
                            <Spinner style={{ color: "#FF2C64" }} />
                            <Typography
                              className="mt-2"
                              style={{ fontFamily: "Poppins" }}
                            >
                              Se înlocuiește rețeta...
                            </Typography>
                          </div>
                        ) : (
                          <>
                            <MeniuMeseCard
                              token={token}
                              id={meal.id}
                              isFavorite={meal.isFavorite}
                              image={meal.imageUrl}
                              // onClick={() => handleReplace(meal.id)}
                              onClick={() => {
                                setModalMealOpen(true);
                                setCurrentMeal(meal);
                              }}
                            />

                            <Typography
                              className="mt-2"
                              sx={{
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                fontSize: "12px",
                                paddingRight: 8,
                              }}
                            >
                              {meal.title ? (
                                <>{meal.title}</>
                              ) : (
                                "Aici o să vină titlul unei rețete"
                              )}
                            </Typography>
                          </>
                        )}
                      </div>
                    </Col>
                  ))
                )}
                <Modal
                  show={modalMealOpen}
                  onHide={() => setModalMealOpen(false)}
                  centered
                >
                  <Modal.Body>
                    <RecipeCardTemp
                      dontTruncate={true}
                      text={currentMeal.title}
                      description={currentMeal.description}
                      image={currentMeal.imageUrl}
                      isDesktop={true}
                    />

                    <Card
                      style={{
                        fontFamily: "Poppins",
                        // width: "400px",
                        maxHeight: "500px",
                        overflowY: "auto",
                        backgroundColor: "#FFFEF7",
                      }}
                      className="shadow-sm mt-4 p-3"
                    >
                      <Card.Title style={{ fontWeight: "bold" }}>
                        Informații nutriționale
                      </Card.Title>
                      <Card.Body>
                        <Typography
                          className="mt-5"
                          style={{ fontFamily: "Poppins", fontWeight: "bold" }}
                        >
                          Nutriție per porție
                        </Typography>
                        <hr style={{ border: "1px solid #CFCFCF" }} />
                        <Stack direction="horizontal" gap={2}>
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "600",
                              fontSize: "12px",
                            }}
                          >
                            Calorii
                          </Typography>
                          <Typography
                            className="ms-auto"
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "400",
                              fontSize: "12px",
                            }}
                          >
                            {currentMeal.kcal} kcal
                          </Typography>
                        </Stack>
                        <hr style={{ border: "1px solid #CFCFCF" }} />
                        <Stack direction="horizontal" gap={2}>
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "600",
                              fontSize: "12px",
                            }}
                          >
                            Carbohidrați
                          </Typography>
                          <Typography
                            className="ms-auto"
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "400",
                              fontSize: "12px",
                            }}
                          >
                            {currentMeal.carbohydrates} g
                          </Typography>
                        </Stack>
                        <Stack direction="horizontal" gap={2}>
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "600",
                              fontSize: "12px",
                              color: "#797979",
                            }}
                          >
                            Fibre
                          </Typography>
                          <Typography
                            className="ms-auto"
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "400",
                              fontSize: "12px",
                              color: "#797979",
                            }}
                          >
                            {currentMeal.fibers} g
                          </Typography>
                        </Stack>
                        <hr style={{ border: "1px solid #CFCFCF" }} />
                        <Stack direction="horizontal" gap={2}>
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "600",
                              fontSize: "12px",
                            }}
                          >
                            Proteine
                          </Typography>
                          <Typography
                            className="ms-auto"
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "400",
                              fontSize: "12px",
                            }}
                          >
                            {currentMeal.proteins} g
                          </Typography>
                        </Stack>
                        <hr style={{ border: "1px solid #CFCFCF" }} />
                        <Stack direction="horizontal" gap={2}>
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "600",
                              fontSize: "12px",
                            }}
                          >
                            Grăsimi
                          </Typography>
                          <Typography
                            className="ms-auto"
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "400",
                              fontSize: "12px",
                            }}
                          >
                            {currentMeal.fats} g
                          </Typography>
                        </Stack>
                      </Card.Body>
                    </Card>
                  </Modal.Body>
                  <Modal.Footer>
                    <ButtonPrimary
                      onClick={() => handleReplace(currentMeal.id)}
                      text="Înlocuieste"
                    />
                  </Modal.Footer>
                </Modal>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default DashboardMeniuMese;
