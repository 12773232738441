import React, { Component } from "react";
import RegisterState from "./RegisterState";

export default class RegisterStateProvider extends Component  {
    state = {
        username: "",
        password: "",
        fullname: "",
        email: "",
        phoneNumber: "",
        age: "",
        gender: "",
        alimentationType: "",
        energy: false,
        stress: false,
        pcos: false,
        endomethriosis: false,
        bloatingFree: false,
        constipationFree: false,
        lactoseFree: false,
        glutenFree: false,
        foodsToBeExcluded: "aiazic",
        time: "",
        mealPreference: "",
        kcal: 0,
        plan: 0,
        mainObjective: ""
    }

    setUsername = (username) => {
        this.setState({ username });
    }

    setPassword = (password) => {
        this.setState({ password });
    }

    setfullName = (fullname) => {
        this.setState({ fullname });
    }

    setEmail = (email) => {
        this.setState({ email });
    }

    setPhoneNumber = (phoneNumber) => {
        this.setState({ phoneNumber });
    }

    setAge = (age) => {
        this.setState({ age });
    }

    setGender = (gender) => {
        this.setState({ gender });
    }

    setAlimentationType = (alimentationType) => {
        this.setState({ alimentationType });
    }

    setEnergy = (energy) => {
        this.setState({ energy });
    }

    setStress = (stress) => {
        this.setState({ stress });
    }

    setPcos = (pcos) => {
        this.setState({ pcos });
    }

    setEndomethriosis = (endomethriosis) => {
        this.setState({ endomethriosis });
    }

    setBloatingFree = (bloatingFree) => {
        this.setState({ bloatingFree });
    }

    setConstipationFree = (constipationFree) => {
        this.setState({ constipationFree });
    }

    setLactoseFree = (lactoseFree) => {
        this.setState({ lactoseFree });
    }

    setGlutenFree = (glutenFree) => {
        this.setState({ glutenFree });
    }

    setFoodsToBeExcluded = (foodsToBeExcluded) => {
        this.setState({ foodsToBeExcluded });
    }

    setTime = (time) => {
        this.setState({ time });
    }

    setMealPreference = (mealPreference) => {
        this.setState({ mealPreference });
    }

    setKcal = (kcal) => {
        this.setState({ kcal });
    }

    setPlan = (plan) => {
        this.setState({ plan });
    }

    setMainObjective = (mainObjective) => {
        this.setState({ mainObjective });
    }

    render() {
        return (
            <RegisterState.Provider
            value={{
                username: this.state.username,
                setUsername: this.setUsername,
                password: this.state.password,
                setPassword: this.setPassword,
                fullname: this.state.fullname,
                setfullName: this.setfullName,
                email: this.state.email,
                setEmail: this.setEmail,
                phoneNumber: this.state.phoneNumber,
                setPhoneNumber: this.setPhoneNumber,
                age: this.state.age,
                setAge: this.setAge,
                gender: this.state.gender,
                setGender: this.setGender,
                alimentationType: this.state.alimentationType,
                setAlimentationType: this.setAlimentationType,
                energy: this.state.energy,
                setEnergy: this.setEnergy,
                stress: this.state.stress,
                setStress: this.setStress,
                pcos: this.state.pcos,
                setPcos: this.setPcos,
                endomethriosis: this.state.endomethriosis,
                setEndomethriosis: this.setEndomethriosis,
                bloatingFree: this.state.bloatingFree,
                setBloatingFree: this.setBloatingFree,
                constipationFree: this.state.constipationFree,
                setConstipationFree: this.setConstipationFree,
                lactoseFree: this.state.lactoseFree,
                setLactoseFree: this.setLactoseFree,
                glutenFree: this.state.glutenFree,
                setGlutenFree: this.setGlutenFree,
                foodsToBeExcluded: this.state.foodsToBeExcluded,
                setFoodsToBeExcluded: this.setFoodsToBeExcluded,
                time: this.state.time,
                setTime: this.setTime,
                mealPreference: this.state.mealPreference,
                setMealPreference: this.setMealPreference,
                kcal: this.state.kcal,
                setKcal: this.setKcal,
                plan: this.state.plan,
                setPlan: this.setPlan,
                mainObjective: this.state.mainObjective,
                setMainObjective: this.setMainObjective
            }}
            >
                {this.props.children}
            </RegisterState.Provider>
        );
    }
}