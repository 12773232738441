import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box } from "@mui/material";

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: "bold",
  backgroundColor: "transparent",
  boxShadow: "none",
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: 400,
  backgroundColor: "transparent",
}));

const FAQCard = ({ header, content, expanded, onChange }) => {
  return (
    <Accordion
      style={{
        marginBottom: 20,
        backgroundColor: "transparent",
        boxShadow: "none",
        border: "1px #0C3919 solid",
        borderRadius: "10px",
      }}
      expanded={expanded}
      onChange={onChange}
    >
      <CustomAccordionSummary
        expandIcon={
          expanded ? (
            <Box
              sx={{
                width: "20px",
                height: "20px",
                backgroundColor: "#F3A7BB",
                borderRadius: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <RemoveIcon
                sx={{ color: "#fff", width: "16px", height: "16px" }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                width: "20px",
                height: "20px",
                backgroundColor: "#F3A7BB",
                borderRadius: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "auto",
              }}
            >
              <AddIcon sx={{ color: "#fff", width: "16px", height: "16px" }} />
            </Box>
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {header}
      </CustomAccordionSummary>
      <CustomAccordionDetails>{content}</CustomAccordionDetails>
    </Accordion>
  );
};

export default FAQCard;
